import { CSSProperties } from 'styled-components';

export type StringIndexSignature = {
  [key: string]: CSSProperties | { [key: string]: CSSProperties } | ((value: string) => CSSProperties);
};

export interface SimpleRouteMenu {
  ruta: string;
  dictionario: string;
  codigo: string;
}

export enum DictionaryRoute {
  VOUCHERS = 'cupones',
}

export enum Route {
  VOUCHERS = '/cupones',
  VOUCHER = '/cupon/:id',
  MY_VOUCHERS = '/misCupones',
}

export enum PermissionCode {
  VOUCHERS = 'CUPONES',
}

export type CommonSorting = {
  label: string;
  value: string | number;
};

export enum apiUrlPerson {
  ADDRESSES_LIST = '/api/persona/direcciones/{idPersona}',
  PERSON_POINTS = '/api/Persona/{idPersona}/cliente/{idCliente}/puntos',
  USER_BY_ID = '/api/persona/usuario/{idUsuario}',
  ADDRESS = 'api/persona/direccion',
}

export enum apiUrlCountry {
  countries = '/api/pais',
}

export enum apiUrlState {
  state = '/api/provincia',
}

export enum apiUrlCity {
  city = 'api/localidad/provincia/{idProvincia}',
}

export enum apiUrlRecognitions {
  dashboardRanking = 'api/reconocimiento/dashboard/ranking',
  recognition = '/api/reconocimiento',
  recognitionSupervisor = 'api/reconocimiento/supervisor',
  recognitionApproved = 'api/reconocimiento/aprobar',
  recognitionReceived = 'api/reconocimiento/recibidos',
  myRecognitions = 'api/reconocimiento/my',
}
