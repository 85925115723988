import { useContext } from 'react';
import { ThemeContext } from '../../context';

export const useThemeContext = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return {
    theme,
    setTheme,
  };
};
