import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon } from '@mui/icons-material';
import { Route } from '../../../../common/types';
import { GET_VOUCHERS_STYLES } from './styles';

interface GetVouchersProps {
  emptyListText: string;
  getVouchersText: string;
  getVouchersBtnText: string;
}

export const GetVouchers: React.FC<GetVouchersProps> = ({ emptyListText, getVouchersText, getVouchersBtnText }) => {
  const navigate = useNavigate();
  return (
    <Box sx={GET_VOUCHERS_STYLES.mainContainer}>
      <Box>
        <Button
          variant="text"
          onClick={() => navigate(Route.VOUCHERS)}
        >
          <ConfirmationNumberOutlinedIcon sx={GET_VOUCHERS_STYLES.voucherIcon} />
        </Button>
      </Box>
      <Box>
        <Typography
          variant="h4"
          color="initial"
        >
          {emptyListText}
        </Typography>
        <Typography
          variant="subtitle1"
          color="initial"
          textAlign={'center'}
        >
          {getVouchersText}
        </Typography>
        <Box
          textAlign={'center'}
          pt={2}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(Route.VOUCHERS)}
          >
            {getVouchersBtnText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
