import React from 'react';
import { Modal, Box, IconButton, Button, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material/';
import { VOUCHER_DETAIL_MODAL_STYLES } from './styles';

interface DetailModalProps {
  title: string;
  description: string;
  discount: string;
  priceText: string;
  status: string;
  statusText: string;
  image: string;
  qrCode: string;
  qrCodeAlt: string;
  imageAlt: string;
  downloadButtonText: string;
  emailButtonText: string;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  handleOnPrint: () => void;
}

export const VoucherDetailModal: React.FC<DetailModalProps> = ({
  title,
  description,
  discount,
  priceText,
  status,
  statusText,
  image,
  qrCode,
  imageAlt,
  qrCodeAlt,
  downloadButtonText,
  emailButtonText,
  isModalOpen,
  handleOnPrint,
  setIsModalOpen,
}) => {
  return (
    <Box>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Box
          id="modal"
          sx={VOUCHER_DETAIL_MODAL_STYLES.modal}
        >
          <Box
            sx={VOUCHER_DETAIL_MODAL_STYLES.closeIconContainer}
            onClick={() => setIsModalOpen(false)}
          >
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            id="modal-content"
            sx={VOUCHER_DETAIL_MODAL_STYLES.modalContent}
          >
            <Typography
              variant="h5"
              color="primary"
              textAlign={'center'}
              sx={VOUCHER_DETAIL_MODAL_STYLES.title}
            >
              {title}
            </Typography>

            <Box sx={VOUCHER_DETAIL_MODAL_STYLES.body}>
              <Box sx={VOUCHER_DETAIL_MODAL_STYLES.imageContainer}>
                <img
                  id="voucher-image"
                  src={image}
                  alt={imageAlt}
                  width="160px"
                  height="160px"
                />
                <img
                  id="voucher-image"
                  src={qrCode}
                  alt={qrCodeAlt}
                  width="160px"
                  height="160px"
                />
              </Box>

              <Box sx={VOUCHER_DETAIL_MODAL_STYLES.descriptionContainer}>
                <Typography
                  variant="body2"
                  textAlign={'center'}
                  margin={2}
                >
                  {description.length > 400 ? description.slice(0, 400) + '...' : description}
                </Typography>

                <Box sx={VOUCHER_DETAIL_MODAL_STYLES.legendContainer}>
                  <Box sx={VOUCHER_DETAIL_MODAL_STYLES.legend}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                    >
                      {priceText}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      color="primary"
                    >
                      {discount}
                    </Typography>
                  </Box>

                  <Box sx={VOUCHER_DETAIL_MODAL_STYLES.legend}>
                    <Typography
                      variant="subtitle1"
                      color="primary"
                    >
                      {statusText}
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      color="primary"
                    >
                      {status}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={VOUCHER_DETAIL_MODAL_STYLES.buttons}>
                  {/* <PDFDownloadLink
                    document={
                      <VoucherDetailPDF
                        title={title}
                        description={description}
                        image={image}
                        qrCode={qrCode}
                        priceText={`${priceText} ${discount}`}
                        statusText={`${statusText} ${status}`}
                      />
                    }
                    fileName="voucher.pdf"
                  >
                    <Button variant="contained">{downloadButtonText}</Button>
                  </PDFDownloadLink>
                */}

                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleOnPrint}
                  >
                    {downloadButtonText}
                  </Button>

                  {/* //_ */}
                  {/* <Button
                    type="button"
                    variant="outlined"
                    // onClick={() => addImageToPDF(title, qrCode)}
                  >
                    {emailButtonText}
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
