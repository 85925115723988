import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useCommonVoucher } from '../../common/hooks';
import useLanguage from '../../common/hooks/useLanguage';
import { FormateadVoucher, IVoucherDictionary } from '../../common/types/voucher';
import { modalSuccess, formatVouchers } from '../../utils';
import { incrementar } from '../../common/redux/features/contadorItemReducer';
import { addToCart } from '../../common/redux/features/carritoReducer';
import ES from '../../common/language/voucher/es.json';
import EN from '../../common/language/voucher/en.json';

export const useVoucher = (idCupon: number) => {
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IVoucherDictionary };
  const dispatch = useDispatch();

  const {
    voucherDetailQuery: { data: voucher, isLoading, isError },
  } = useCommonVoucher({
    idCupon,
  });

  const formateadVoucher: FormateadVoucher[] = useMemo(
    () => (voucher ? formatVouchers([voucher], dictionary) : []),
    [voucher, dictionary]
  );

  const handleRedeem = (): void => {
    dispatch(incrementar());
    dispatch(
      addToCart({
        Imagen: voucher?.imagenGrande,
        Descripcion: voucher?.descripcion,
        puntos: voucher?.puntos,
        puntosPorUnidad: voucher?.puntos,
        Nombre: voucher?.nombre,
        precio: voucher?.descuento,
        Marca: formateadVoucher[0].descuentoText,
        idCupon: voucher?.idCupon,
        cantidad: 1,
        importe: voucher?.descuento,
        idCuponEstado: 1,
      })
    );
    modalSuccess(dictionary.notifications.addedToCart, 1500);
  };

  return {
    dictionary,
    voucher,
    isLoading,
    isError,
    formateadVoucher: formateadVoucher[0],
    handleRedeem,
  };
};
