import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { apiUrlRecognitions } from '../../../common/types';

export const getApprovedRecognitions = async (queryParams: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(apiUrlRecognitions.recognitionSupervisor, queryParams);
  try {
    const response: IRecognitions = await fetchContent(URL);
    return response;
  } catch (error) {
    console.error('[getApprovedRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
