export const API_VIDEO_URL = {
  educationLevel: '/api/video/niveleducacion',
};

export const API_TYPE_OF_TRANSACTIONS_URL = {
  all: '/api/tipomovimiento',
  byUser: '/api/tipomovimiento/persona',
};

export const API_TRANSACTIONS_URL = {
  transaction: '/api/movimiento',
  transactionDetail: '/api/movimiento/{idMovimiento}',
  redeemedCategories: 'api/movimiento/dashboard/categoriascanjeadas/{idPersona}',
  consolidatedPersonHome: '/api/movimiento/persona/consolidado/home',
  consolidatedPerson: '/api/movimiento/persona/consolidado',
};

export const API_HOME_CARROUSEL_URL = {
  clientAndLanguage: '/api/HomeCarousel/Cliente/{cliente}/lenguaje/{lenguaje}',
};

export const API_SUPERVISOR_URL = {
  supervisorById: 'api/supervisor/{idUsuario}',
};

export const API_USERS_URL = {
  supervisorUsers: 'api/usuario/supervisor/usuarios',
  listOfConsumers: 'api/usuario/cliente/{idCliente}/consumidor_list_component',
};

export const API_TYPE_OF_RECOGNITION_URL = {
  bySector: '/api/tipoReconocimiento/sector/{idSector}',
};

export const API_LIRIUM_USER_URL = {
  cryptoPoints: '/api/liriumusuario/puntosporcrypto',
  user: '/api/liriumusuario',
  userById: '/api/liriumUsuario/usuario/{idUsuario}',
  customer: '/api/liriumusuario/customer',
  customerById: '/api/liriumusuario/customer/{idCustomer}',
  customerAccountsByCustomerId: '/api/liriumusuario/customer/{customerId}/accounts',
  customerFile: '/api/liriumusuario/customer/{idCustomer}/file/{documentType}',
};

export const API_LIRIUM_ORDER_URL = {
  order: '/api/liriumorder',
  orderState: '/api/liriumorder/{id}/{state}/{idMovimiento}',
  ordersByIdCustomer: '/api/liriumorder/orders/{idCustomer}', // en el swagger se ve .../orders/{customer}
  exchangeRates: 'api/liriumorder/exchange_rates/{currency}',
  confirmOrder: '/api/liriumorder/orders/confirm/{customerId}/{ordenId}',
};

export const API_LIRIUM_HISTORICAL_PRICE = {
  currencyType: '/api/liriumHistoricalPrice/moneda/{currencyType}/period/month/top/{amount}/{currency}',
};

export const API_EDUCATION_LEVEL_URL = {
  financialEducation: '/api/NivelEducacionVideoUsuario/porcentaje',
};

export const API_CLIENT_CATALOGO_COUNTRY_URL = {
  countries: '/api/clienteCatalogoPais/PaisesBy/{idCliente}',
};

export const API_CATALOGO_URL = {
  catalogo: '/api/Catalogo/{idCliente}',
  catalogoMetadata: '/api/Catalogo/{idCliente}/metadata',
};

export const API_PRODUCT_URL = {
  detailWeb: '/api/producto/detalleweb/v2/{id}',
};

export const API_OH_GIFT_CARD_URL = {
  branches: '/api/OhGiftCard/sucursales/{codigoProductoProveedor}',
};

export const API_CLIENT_URL = {
  pointsPerAmountByIdProducto:
    '/api/cliente/puntosporimporte/{idCliente}/{idUsuario}/{idTipoMovimiento}/{importe}/{idProducto}',
  pointsPerAmount: '/api/cliente/puntosporimporte/{idCliente}/{idUsuario}/{idTipoMovimiento}/{importe}',
  clientCurrency: '/api/cliente/moneda/{idCliente}',
  amountPerPoints: '/api/cliente/importeporpuntos/{idCliente}/{idUsuario}/{idTipoMovimiento}/{puntos}',
  theme: '/api/cliente/{idCliente}/colorestilos',
  footer: '/api/Cliente/{idCliente}/datosfooter',
};

export const API_CURRENCY_URL = {
  currencyById: '/api/moneda/{id}',
};

export const API_PERMISSIONS_URL = {
  environment: '/api/permiso/usuario/{idUsuario}/entorno/{entorno}',
};

export const API_VOUCHERS_URL = {
  vouchers: '/api/cupon/cliente',
  voucher: '/api/cupon/detalle/{idCupon}',
  exchangedVouchers: 'api/cupon/miscupones',
  exchangedVoucher: 'api/cupon/micupon/{idCuponMovimiento}',
};
