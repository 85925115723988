import { apiUrlCity, City, CityByIdProvincia } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCities = async ({ idProvincia }: CityByIdProvincia): Promise<City[]> => {
  const URL: string = apiUrlCity.city.replace('{idProvincia}', String(idProvincia));

  try {
    const cities: City[] = await fetchContent(URL);

    return cities;
  } catch (error: any) {
    console.error('[getCities]: ' + error);
    throw handleServiceError(error);
  }
};
