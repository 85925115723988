import React from 'react';
import { Grid } from '@mui/material';
import { CustomCount, PRODUCTS_TO_REDEEM_STYLES, SelectGiftCard } from '..';
import { ProductsToRedeemProps } from '../../../../common/types';

interface Props {
  productsToRedeem: ProductsToRedeemProps;
}

export const ProductsToRedeem: React.FC<Props> = ({
  productsToRedeem: {
    amount,
    currency,
    isError,
    exchangeNotAllowed,
    isShowingGiftCardList,
    isRangeOfPrices,
    maxPrice,
    product,
    products,
    salePrice,
    minPriceText,
    maxPriceText,
    giftCardPrice,
    selectAGiftCardButtonText,
    handleOnSelectGiftCardChange,
    handleOnInputChange,
    handleOnClick,
    handleArrowOnClick,
  },
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={PRODUCTS_TO_REDEEM_STYLES.mainContainer}
    >
      {isShowingGiftCardList ? (
        <SelectGiftCard
          products={products}
          giftCardPrice={giftCardPrice}
          selectAGiftCardButtonText={selectAGiftCardButtonText}
          handleOnSelectGiftCardChange={handleOnSelectGiftCardChange}
          handleOnClick={handleOnClick}
        />
      ) : (
        isRangeOfPrices && (
          <CustomCount
            exchangeNotAllowed={exchangeNotAllowed}
            maxPrice={maxPrice}
            minPrice={salePrice}
            amount={amount}
            isError={isError}
            currency={currency}
            product={product}
            minPriceText={minPriceText}
            maxPriceText={maxPriceText}
            handleArrowOnClick={handleArrowOnClick}
            handleOnInputChange={handleOnInputChange}
          />
        )
      )}
    </Grid>
  );
};
