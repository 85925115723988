import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, FormControl } from '@mui/material';
import InputForm from '../../components/Inputs/InputForm/InputForm';
import { cliente, formularioEstructura } from '../../common/constants/constants';
import ShortLine from '../../components/ShortLine/ShortLine';
import useLanguage from '../../common/hooks/useLanguage';
import ES from '../../common/language/contactanos/es.json';
import EN from '../../common/language/contactanos/en.json';
import { formularioContactoInterface, motivoInterface } from '../../common/interfaces/ContactInterface';
import { motivoService } from '../../services/ContactService';
import { formValidation } from '../../utils/functions';
import { modalError } from '../../utils/validaciones';
import { LanguageContext } from '../../context/LanguageContext';
import { CONTACT_STYLES } from './styles';

const Contacto = () => {
  const [form, setForm] = useState<formularioContactoInterface>(formularioEstructura);
  const [motivos, setMotivos] = useState<motivoInterface[] | null>(null);

  const { dictionary } = useLanguage({ EN, ES });
  const navigate = useNavigate();
  const language = useContext(LanguageContext);

  useEffect(() => {
    motivoService(language.language)
      .then((data) => setMotivos(data))
      .catch(() => modalError('No se logró traer los motivos'));
  }, [language.language]);

  const handleChange = (e: any) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let dateNow = new Date().toISOString();
    const updatedForm = { ...form, fecha: dateNow, idCliente: cliente };
    try {
      const isValid = await formValidation(updatedForm, dictionary, language.language);
      if (isValid) {
        navigate('/agradecimiento');
      }
    } catch (error: any) {
      modalError('Error');
    }
  };

  return (
    <Box sx={CONTACT_STYLES.mainContainer}>
      <Typography
        variant="h4"
        color="primary"
      >
        {dictionary?.contactanos}
        <ShortLine position="left" />
      </Typography>
      <Box sx={CONTACT_STYLES.subtitleContainer}>
        <Typography
          variant="h6"
          color="primary"
          textAlign="center"
          maxWidth="900px"
          width="90%"
        >
          {dictionary?.invitacionContacto}
        </Typography>
        <ShortLine position="center" />
      </Box>
      <form
        action="POST"
        onSubmit={submit}
      >
        <FormControl sx={CONTACT_STYLES.formControl}>
          <Box sx={CONTACT_STYLES.formFieldsContainer}>
            <Box sx={CONTACT_STYLES.nameContainer}>
              <InputForm
                label={dictionary?.form.nombre}
                type="text"
                name="nombre"
                form={form}
                setForm={setForm}
              />
              <InputForm
                label={dictionary?.form.email}
                type="email"
                name="email"
                form={form}
                setForm={setForm}
              />
            </Box>
            <Box sx={CONTACT_STYLES.inputContainer}>
              <label htmlFor="select">{dictionary?.form.motivo}</label>
              <select
                name="idMotivoContacto"
                id="select"
                value={form.idMotivoContacto}
                style={CONTACT_STYLES.inputForm}
                onChange={handleChange}
              >
                <option
                  value={-1}
                  disabled
                  defaultValue={-1}
                >
                  {dictionary?.form.seleccioneMotivo}
                </option>
                {motivos?.map((motivo) => (
                  <option
                    key={`${motivo.idMotivoContacto}-motivo`}
                    value={motivo.idMotivoContacto}
                  >
                    {motivo.descripcion}
                  </option>
                ))}
              </select>
            </Box>
            <InputForm
              label={dictionary?.form.asunto}
              type="text"
              name="asunto"
              form={form}
              setForm={setForm}
            />
            <Box sx={CONTACT_STYLES.inputContainer}>
              <label>{dictionary?.form.consulta}</label>
              <textarea
                name="descripcion"
                style={{ ...CONTACT_STYLES.textArea, ...CONTACT_STYLES.inputForm }}
                onChange={handleChange}
              ></textarea>
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={CONTACT_STYLES.sendButton}
          >
            {dictionary?.form.enviar}
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};

export default Contacto;
