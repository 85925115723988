import { ViewTable } from '../../components/ViewTable/ViewTable';
import { useMyTransactions } from '../../hooks/transactions/useMyTransactions';
import { getValueFromObject } from '../../utils';
import {
  AssignmentDetail,
  CryptoExchangeDetail,
  MyTransactionDetailContainer,
  PointsExchangeDetail,
  RecognitionDetail,
  TypeOfTransactionMenu,
} from './components';

export const MyTransactions = () => {
  const {
    myTransactionsColumns,
    myTransactionsRows,
    isLoading,
    dictionary,
    page,
    sort,
    totalPages,
    typeOfTransaction,
    isViewDetailOpen,
    transactionDetail,
    exchangePointsDetailFormateadData,
    recognitionDetailFormateadData,
    isProductsPointsExchangeDetail,
    isRecognitionDetail,
    isPointsAssignmentDetail,
    pointsAssignmentDetailFormateadData,
    isCryptoExchangeDetail,
    cryptoExchangeDetailFormateadData,
    typeOfTransactionMenuFormateadData,
    changeSort,
    setPage,
    handleChangePage,
    setTypeOfTransaction,
    setIsViewDetailOpen,
  } = useMyTransactions();

  return (
    <>
      <ViewTable
        title={dictionary.title}
        searchBarPlaceHolder={dictionary.form.search.placeHolder}
        isLoading={isLoading}
        rows={myTransactionsRows}
        page={page}
        totalPages={totalPages}
        noResults={dictionary.table.noResults}
        columns={myTransactionsColumns}
        sort={sort}
        showSearchBar={false}
        navbar={
          <TypeOfTransactionMenu
            title={dictionary.typeOfTransactionMenu.title}
            typeOfTransaction={typeOfTransactionMenuFormateadData}
            typeOfTransactionSelected={typeOfTransaction}
            setTypeOfTransaction={setTypeOfTransaction}
          />
        }
        setPage={setPage}
        changeSort={changeSort}
        handleChangePage={handleChangePage}
      />

      {isProductsPointsExchangeDetail && (
        <MyTransactionDetailContainer
          title={dictionary.detailModal.pointsExchange.title}
          idTransaction={transactionDetail?.idMovimiento}
          date={exchangePointsDetailFormateadData.date}
          points={exchangePointsDetailFormateadData.points}
          detailModal={dictionary.detailModal.commonItems}
          children={
            <PointsExchangeDetail
              title={dictionary.detailModal.pointsExchange.productsTableTitle}
              columns={exchangePointsDetailFormateadData.productsTableHeaders}
              products={exchangePointsDetailFormateadData.products}
              idStatus={transactionDetail?.canjePuntos?.productos[0]?.idEstado}
              statusTitle={dictionary.detailModal.pointsExchange.status}
              status={getValueFromObject(
                dictionary.detailModal.pointsExchange.statusConfig,
                transactionDetail?.canjePuntos?.productos[0]?.idEstado
              )}
            />
          }
          isModalOpen={isViewDetailOpen}
          setIsModalOpen={setIsViewDetailOpen}
        />
      )}

      {isRecognitionDetail && (
        <MyTransactionDetailContainer
          title={dictionary.detailModal.recognition.title}
          idTransaction={transactionDetail?.idMovimiento}
          date={recognitionDetailFormateadData.date}
          points={recognitionDetailFormateadData.points}
          detailModal={dictionary.detailModal.commonItems}
          image={transactionDetail?.reconocimiento?.imagen}
          children={
            <RecognitionDetail
              recognitionTitle={dictionary.detailModal.recognition.recognition}
              recognizedByTitle={dictionary.detailModal.recognition.recognizedBy}
              recognitionCommentTitle={dictionary.detailModal.recognition.comment}
              recognition={transactionDetail?.reconocimiento?.tituloTipoReconocimiento}
              recognizedBy={transactionDetail?.reconocimiento?.nombreUsuarioAsignador}
              comment={transactionDetail?.reconocimiento?.descripcion}
            />
          }
          isModalOpen={isViewDetailOpen}
          setIsModalOpen={setIsViewDetailOpen}
        />
      )}

      {isPointsAssignmentDetail && (
        <MyTransactionDetailContainer
          title={pointsAssignmentDetailFormateadData.title}
          idTransaction={transactionDetail?.idMovimiento}
          date={pointsAssignmentDetailFormateadData.date}
          points={pointsAssignmentDetailFormateadData.points}
          detailModal={dictionary.detailModal.commonItems}
          children={
            <AssignmentDetail
              commentTitle={dictionary.detailModal.assignment.commentTitle}
              comment={transactionDetail?.comentario}
            />
          }
          isModalOpen={isViewDetailOpen}
          setIsModalOpen={setIsViewDetailOpen}
        />
      )}

      {isCryptoExchangeDetail && (
        <MyTransactionDetailContainer
          title={cryptoExchangeDetailFormateadData.title}
          idTransaction={transactionDetail?.idMovimiento}
          date={cryptoExchangeDetailFormateadData.date}
          points={cryptoExchangeDetailFormateadData.points}
          detailModal={dictionary.detailModal.commonItems}
          children={
            <CryptoExchangeDetail
              currencyTitle={dictionary.detailModal.cryptoExchange.currency}
              amountTitle={dictionary.detailModal.cryptoExchange.amount}
              currency={transactionDetail?.crypto?.monedaDestino}
              amount={cryptoExchangeDetailFormateadData.amount}
            />
          }
          isModalOpen={isViewDetailOpen}
          setIsModalOpen={setIsViewDetailOpen}
        />
      )}
    </>
  );
};
