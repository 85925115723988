import { usePerson } from '../../../common/hooks';
import { IFormCarrito } from '../../../common/interfaces/CarritoInterface';

interface CarritoTotalContainer {
  addressesListIdFormaEntrega?: number;
}

export const useCarritoTotalContainer = ({ addressesListIdFormaEntrega }: CarritoTotalContainer) => {
  const {
    addressesListQuery: { data: addressesList },
  } = usePerson({ addressesListIdFormaEntrega });

  const handleSelectAddress = (
    pValue: string,
    form: IFormCarrito,
    setForm: React.Dispatch<React.SetStateAction<IFormCarrito>>
  ): void => {
    const value: string[] = pValue.split(',');
    const idPais: string = value[0].trim();
    const idProvincia: string = value[1].trim();
    const idLocalidad: string = value[2].trim();
    const domicilio: string = value[3].trim();

    setForm({
      ...form,
      idPais,
      idProvincia,
      idLocalidad,
      domicilio,
    });
  };

  return {
    addressesList,
    handleSelectAddress,
  };
};
