import { useCallback, useMemo, useState } from 'react';
import { useCommonVoucher } from '../../common/hooks';
import { FormateadExchangedVoucher, FormateadExchangedVouchers } from '../../common/types/voucher';
import { formatExchangedVoucher } from '../../utils';
import { VOUCHERS_PER_PAGE } from './constants';
import { useVouchers } from './useVouchers';
import { formatExchangedVouchers } from '../../utils/vouchers/formatExchangedVouchers';

export const useMyVouchers = () => {
  const [isViewDetailOpen, setIsViewDetailOpen] = useState<boolean>(false);
  const [myVoucherDetail, setMyVoucherDetail] = useState<number>(0);

  const {
    dictionary,
    currentSorting,
    page,
    isSelectedOption,
    handleChangePage,
    handleSelectOption,
    setIsSelectedOption,
  } = useVouchers();

  const {
    exchangedVouchersQuery: { data: vouchers, isLoading, isError },
    exchangedVoucherQuery: { data: exchangedVoucherDetail },
  } = useCommonVoucher({
    exchangedVouchers: {
      ordenamiento: currentSorting,
      pagina: page,
      cantidadRegistrosPorPagina: VOUCHERS_PER_PAGE,
    },
    idCuponMovimiento: myVoucherDetail,
  });

  const formateadVouchers: FormateadExchangedVouchers[] = useMemo(
    () => (vouchers?.data?.length > 0 ? formatExchangedVouchers(vouchers?.data, dictionary) : []),
    [vouchers?.data, dictionary]
  );

  const formateadExchangedVoucher: FormateadExchangedVoucher = useMemo(
    () => (exchangedVoucherDetail ? formatExchangedVoucher(exchangedVoucherDetail, dictionary) : null),
    [exchangedVoucherDetail, dictionary]
  );

  const isVouchers: boolean = Boolean(formateadVouchers.length > 0);

  const handleViewDetail = useCallback((idCuponMovimiento: number): void => {
    setMyVoucherDetail(idCuponMovimiento);
    setIsViewDetailOpen((current) => !current);
  }, []);

  const handleOnPrint = useCallback((): void => {
    window.print();
  }, []);

  return {
    dictionary,
    currentSorting,
    vouchers,
    page,
    formateadVouchers,
    exchangedVoucherDetail,
    formateadExchangedVoucher,
    isSelectedOption,
    isLoading,
    isError,
    isVouchers,
    isViewDetailOpen,
    setIsViewDetailOpen,
    handleChangePage,
    handleSelectOption,
    setIsSelectedOption,
    handleViewDetail,
    handleOnPrint,
  };
};
