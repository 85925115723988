import React from 'react';
import { Box, Button, FormControl, OutlinedInput, Typography } from '@mui/material';
import { AmountSelectorProps } from '../../../../common/types';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import { AMOUNT_SELECTOR_STYLES } from '..';

interface IAmountSelector {
  amountSelector: AmountSelectorProps;
}

export const AmountSelector: React.FC<IAmountSelector> = ({
  amountSelector: { isShowingAmountSelector, amountOfGiftCards, amountText, handleOnClickSelectGiftCardButton },
}) => {
  if (!isShowingAmountSelector) return <></>;

  return (
    <Box sx={AMOUNT_SELECTOR_STYLES.mainContainer}>
      <Typography
        variant="subtitle1"
        width="100%"
      >
        {amountText}
      </Typography>
      <Box sx={AMOUNT_SELECTOR_STYLES.inputContainer}>
        <Button
          disabled={amountOfGiftCards === 1}
          variant="contained"
          disableElevation
          size="small"
          color="btnPrimary"
          itemID="prev"
          sx={{ ...AMOUNT_SELECTOR_STYLES.removeButton, ...AMOUNT_SELECTOR_STYLES.button }}
          children={<RemoveCircleOutlineIcon />}
          onClick={handleOnClickSelectGiftCardButton}
        />

        <FormControl sx={AMOUNT_SELECTOR_STYLES.formControl}>
          <OutlinedInput
            sx={AMOUNT_SELECTOR_STYLES.input}
            value={amountOfGiftCards}
            inputMode="numeric"
            readOnly
          />
        </FormControl>

        <Button
          variant="contained"
          disableElevation
          size="small"
          color="btnPrimary"
          itemID="next"
          sx={{ ...AMOUNT_SELECTOR_STYLES.addButton, ...AMOUNT_SELECTOR_STYLES.button }}
          children={<AddCircleOutlineIcon />}
          onClick={handleOnClickSelectGiftCardButton}
        />
      </Box>
    </Box>
  );
};
