import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useVoucher } from '../../../../hooks/voucher';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';
import { NoResults } from '../../../../components/NoResults/NoResults';
import { VOUCHER_DETAIL_STYLES } from './styles';

export const VoucherDetail = () => {
  const { id } = useParams();
  const { dictionary, voucher, formateadVoucher, isLoading, isError, handleRedeem } = useVoucher(Number(id));

  return (
    <Box sx={VOUCHER_DETAIL_STYLES.mainContainer}>
      <Grid
        container
        columns={12}
        paddingBottom={5}
      >
        <Grid
          item
          sm={12}
        >
          <Typography
            variant="h4"
            color="primary"
          >
            {dictionary.detail.title}
            <ShortLine position="left" />
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
        >
          {isLoading ? (
            <MiniLoader />
          ) : !voucher || isError ? (
            <NoResults
              text={'No results'}
              {...{ color: 'primary' }}
            />
          ) : (
            <Grid
              container
              columns={12}
              p={2}
              maxWidth={'1400px'}
            >
              <Grid
                item
                sm={12}
                md={5}
              >
                <img
                  src={formateadVoucher?.imagenGrande}
                  style={VOUCHER_DETAIL_STYLES.image}
                  alt={formateadVoucher?.titulo}
                />
              </Grid>

              <Grid
                item
                sm={12}
                md={6}
                p={2}
                sx={VOUCHER_DETAIL_STYLES.infoContainer}
              >
                <Box sx={VOUCHER_DETAIL_STYLES.titleContainer}>
                  <Typography
                    color="initial"
                    fontSize={35}
                  >
                    {formateadVoucher?.titulo}
                  </Typography>
                </Box>

                <Typography variant="body1">{formateadVoucher?.descripcion}</Typography>

                <Grid
                  container
                  sx={VOUCHER_DETAIL_STYLES.priceContainer}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                  >
                    {formateadVoucher?.puntos}
                  </Typography>

                  <Typography
                    variant="h6"
                    color="primary"
                  >
                    {formateadVoucher?.descuentoText}
                  </Typography>
                </Grid>

                <Grid
                  container
                  columns={12}
                  spacing={3}
                >
                  <Grid
                    item
                    sm={6}
                    md={6}
                  >
                    <Button
                      variant="contained"
                      color="btnPrimary"
                      fullWidth
                      onClick={handleRedeem}
                    >
                      {dictionary.buttons.redeem}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
