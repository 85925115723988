import { Grid, Typography } from "@mui/material";
import React from "react";
import useLanguage from "../../common/hooks/useLanguage";
import ShortLine from "../../components/ShortLine/ShortLine";
import MisDatosContainerForm from "./components/MisDatosContainerForm/MisDatosContainerForm";
import DatosDeLaCuentaForm from "./components/DatosDeLaCuentaForm/DatosDeLaCuentaForm";
import DatosPersonalesForm from "./components/DatosPersonalesForm/DatosPersonalesForm";
import { AppContext } from "../../context/AppContext";
import { formMisDatosInterface } from "../../common/interfaces/MisDatosInterface";
import { Loading } from "../../components";
import EN from "../../common/language/shared/en.json";
import ES from "../../common/language/shared/es.json";
import { getUsuarioById } from "../../services/UsuarioService";
import { DatosDeLasDirecciones } from "./components/DatosDeLasDirecciones/DatosDeLasDirecciones";

const MisDatos = () => {
  const [form, setForm] = React.useState<formMisDatosInterface | null>(null);

  const appContext = React.useContext(AppContext);
  const { dictionary } = useLanguage({ EN, ES });

  React.useEffect(() => {
    getUsuarioById(appContext?.user?.idUsuario).then((dataUser) => {
      setForm({ ...dataUser, avatar: appContext?.user?.avatar });
    });
  }, [appContext?.user?.idUsuario, appContext?.user?.avatar]);

  return (
    <>
      {!form ? (
        <Loading />
      ) : (
        <Grid container columns={12} paddingTop={4} paddingX={2} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary" align="left">
              {dictionary?.header.misDatos}
              <ShortLine position="left" />
            </Typography>
            <MisDatosContainerForm>
              <DatosDeLaCuentaForm />
            </MisDatosContainerForm>
            {/* segundo form datos personales de direccion etc */}
            <MisDatosContainerForm>
              <DatosPersonalesForm form={form} setForm={setForm} />
            </MisDatosContainerForm>
            <MisDatosContainerForm>
              <DatosDeLasDirecciones form={form} setForm={setForm} />
            </MisDatosContainerForm>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MisDatos;
