import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeItem } from '../redux/features/carritoReducer';
import { disminuirPorCantidad } from '../redux/features/contadorItemReducer';
import { RootState } from '../redux/store';
import useLanguage from './useLanguage';
import ES from '../language/catalogo/es.json';
import EN from '../language/catalogo/en.json';

const useCarrito = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { dictionary } = useLanguage({ EN, ES });

  const { contadorItem } = useSelector((state: RootState) => state.contadorItem);
  const carrito = useSelector((state: RootState) => state.carrito);
  const navigate = useNavigate();

  const handleRemove = (index: number, cantidad: number) => {
    dispatch(removeItem(index));
    dispatch(disminuirPorCantidad(cantidad));
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(!open);
  };

  const amountOfItemsAdded: number = carrito.reduce((acc, item) => acc + item.cantidad, 0);
  return {
    open,
    contadorItem,
    carrito,
    amountOfItemsAdded,
    dictionary,
    handleRemove,
    setOpen,
    navigate,
    toggleDrawer,
  };
};

export default useCarrito;
