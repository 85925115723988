import { CSSProperties } from 'styled-components';

export const CUSTOM_PAGINATION_STYLES = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 3,
  } as CSSProperties,
  paginationItem: (bgColor: string): CSSProperties => {
    return {
      '&.Mui-selected': {
        backgroundColor: bgColor, // Color de fondo para el número de página seleccionado
        color: 'white', // Color del texto para el número de página seleccionado
      },
    } as CSSProperties;
  },
};
