import axios from 'axios';
import { IRecognitionsForm } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { handleServicesError } from '../../../utils';
import { apiUrlRecognitions } from '../../../common/types';

export const postRecognition = async (body: IRecognitionsForm): Promise<void> => {
  const { idCliente, idUsuario, idTipoReconocimiento, descripcion, usuariosReconocidos } = body;
  try {
    if (usuariosReconocidos.length > 0) {
      const idUsuariosReconocidos = usuariosReconocidos.map((user) => {
        return { idUsuario: user };
      });

      const recoBody = {
        idCliente,
        IdUsuarioAsignador: idUsuario,
        Descripcion: descripcion,
        UsuariosReconocidos: idUsuariosReconocidos,
        IdTipoReconocimiento: idTipoReconocimiento,
      };

      const response: void = await fetchContent(apiUrlRecognitions.recognition, {
        method: 'POST',
        headers: {
          'x-api-version': '2.0',
        },
        body: recoBody,
      });

      return response;
    }
  } catch (error: any) {
    console.error(`[postRecognition]: ${error}`);
    let errorMessage: string = 'Ocurrió un error inesperado';

    if (axios.isAxiosError(error)) {
      errorMessage = handleServicesError(error.response.data?.errors)[0];
    }
    throw Error(errorMessage);
  }
};
