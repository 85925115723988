import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SaleContainerProps } from '../../../../common/types';
import { formatNumber } from '../../../../utils';
import { SALE_CONTAINER_STYLES } from './styles';

interface Props {
  saleContainer: SaleContainerProps;
}

export const SaleContainer: React.FC<Props> = ({ saleContainer: { hasOffer, sale, points, pointsText } }) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
    >
      {hasOffer && (
        <Typography
          fontSize={14}
          color="gray"
          sx={SALE_CONTAINER_STYLES.saleTex}
        >
          {pointsText.replace('{points}', formatNumber(sale))}
        </Typography>
      )}

      {points > 0 && (
        <Typography
          variant="h6"
          color="primary"
        >
          {pointsText.replace('{points}', formatNumber(points))}
        </Typography>
      )}
    </Grid>
  );
};
