import { useQuery } from '@tanstack/react-query';
import { useAppContext, useLanguageContext } from '..';
import { getVouchers, getVoucher, getExchangedVouchers, getExchangedVoucher } from '../../../services/voucher';
import { VouchersDTOQueryParams } from '../../types/voucher';
import { EXCHANGED_VOUCHERS_STALE_TIME_5_MIN } from './constants';

interface VoucherProps {
  vouchersByClient?: VouchersDTOQueryParams;
  exchangedVouchers?: VouchersDTOQueryParams;
  idCupon?: number;
  idCuponMovimiento?: number;
}

export const useCommonVoucher = ({ vouchersByClient, idCupon, exchangedVouchers, idCuponMovimiento }: VoucherProps) => {
  const { idUsuario } = useAppContext();
  const { language } = useLanguageContext();

  const vouchersQuery = useQuery({
    queryKey: [
      'vouchers-by-idClient',
      { idUsuario, language, page: vouchersByClient?.pagina, sort: vouchersByClient?.ordenamiento },
    ],
    queryFn: async () => await getVouchers({ ...vouchersByClient, idioma: language }),
    enabled: Boolean(idUsuario && vouchersByClient),
  });

  const voucherDetailQuery = useQuery({
    queryKey: ['voucher-detail', idCupon ?? '', { idUsuario, language }],
    queryFn: async () => await getVoucher({ idCupon, idioma: language }),
    enabled: Boolean(idUsuario && idCupon),
  });

  const exchangedVouchersQuery = useQuery({
    queryKey: [
      'exchanged-vouchers',
      { idUsuario, language, page: exchangedVouchers?.pagina, sort: exchangedVouchers?.ordenamiento },
    ],
    queryFn: async () => await getExchangedVouchers({ ...exchangedVouchers, idioma: language }),
    enabled: Boolean(idUsuario && exchangedVouchers),
    staleTime: EXCHANGED_VOUCHERS_STALE_TIME_5_MIN,
  });

  const exchangedVoucherQuery = useQuery({
    queryKey: ['exchanged-voucher', { idUsuario, idCuponMovimiento }],
    queryFn: async () => await getExchangedVoucher(idCuponMovimiento),
    enabled: Boolean(idUsuario && idCuponMovimiento > 0),
  });

  return {
    vouchersQuery,
    voucherDetailQuery,
    exchangedVouchersQuery,
    exchangedVoucherQuery,
  };
};
