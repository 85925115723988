import { CSSProperties } from 'styled-components';

export const VOUCHER_CARD_STYLES = {
  voucherContainerMainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
    width: '100%',
    padding: '15px 0',
  } as CSSProperties,
  voucherCard: {
    transition: '0.3s',
    cursor: 'pointer',
    overflow: 'hidden',
    width: '270px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
    borderRadius: '10px',
  } as CSSProperties,
  voucherCardHover: () =>
    ({
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 6px 24px',
      },
    } as CSSProperties),
  imageContainer: {
    display: 'flex',
    width: '100%',
    height: '150px',
    position: 'relative',
  } as CSSProperties,
  image: {
    width: '100%',
    transform: 'scale(1.05)',
    height: '150px',
  } as CSSProperties,
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 15px',
  } as CSSProperties,
  title: {
    fontSize: '19px',
    fontWeight: 'bold',
  } as CSSProperties,
  discountText: {
    fontSize: '15px',
    fontWeight: 400,
  } as CSSProperties,
  pointsContainer: {
    width: '100%',
    padding: '10px 15px',
  } as CSSProperties,
  pointsText: {
    fontSize: '14px',
    fontWeight: 700,
  } as CSSProperties,
};
