import { apiUrlPerson, IPersonAddressList } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const getAddressesList = async (idPerson: number): Promise<IPersonAddressList[]> => {
  const URL: string = apiUrlPerson.ADDRESSES_LIST.replace('{idPersona}', String(idPerson));

  try {
    const user: IPersonAddressList[] = await fetchContent(URL);
    return user;
  } catch (error: any) {
    console.error('[getAddressesList]: ' + error);
    throw handleServiceError(error);
  }
};
