import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useLanguage from '../../../common/hooks/useLanguage';
import { validarCamposReconocimiento } from '../../../utils/validaciones';
import { getListOfConsumers, getRecognitionsBySector } from '../../../services/recognitions';
import { useRecognitionsTable } from '../../../common/hooks/useRecognitionsTable';
import { STALE_TIME_RECOGNITION } from '../../../common/constants';
import { cliente } from '../../../common/constants/constants';
import { IConsumer, IRecognitionsDictionary, IRecognitionsForm } from '../../../common/types/recognitions';
import { useRecognitionMutation } from '../../../common/hooks';
import ES from '../../../common/language/recognitions/recognitions/es.json';
import EN from '../../../common/language/recognitions/recognitions/en.json';

export const useRecognitions = () => {
  const [isSendingRecognition, setIsSendingRecognition] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [membersSelected, setMembersSelected] = useState<IConsumer[]>([]);
  const { idUsuario, idSector, language } = useRecognitionsTable();
  const [form, setForm] = useState<IRecognitionsForm>({
    idCliente: cliente,
    idUsuario: idUsuario,
    idTipoReconocimiento: null,
    descripcion: '',
    usuariosReconocidos: [],
  });
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IRecognitionsDictionary };
  const navigate = useNavigate();
  const { postRecognitionMutation } = useRecognitionMutation();

  const handleSubmit = async (): Promise<void> => {
    if (!validarCamposReconocimiento(form, dictionary)) return;

    await postRecognitionMutation.mutateAsync({
      postRecognitionProps: {
        form,
        successMessage: dictionary.form.success,
        setForm,
        setMembersSelected,
      },
    });
    setIsModalOpen(true);
    return;
  };

  const { data: listOfConsumers } = useQuery({
    queryKey: ['list-of-consumers', { idUsuario }],
    queryFn: async () => await getListOfConsumers(cliente),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const typeOfRecognitionsQuery = useQuery({
    queryKey: ['type-of-recognitions', { idUsuario, language: language.language }],
    queryFn: async () => await getRecognitionsBySector({ idSector, lang: language.language }),
    staleTime: STALE_TIME_RECOGNITION,
    enabled: Boolean(idUsuario),
  });

  const handleSelectMember = (element: IConsumer) => {
    const UsuariosReconocidos = form.usuariosReconocidos;
    const isSelected = UsuariosReconocidos.includes(element.idUsuario);
    const newUsuariosReconocidos = isSelected
      ? UsuariosReconocidos.filter((usuario: number) => usuario !== element.idUsuario)
      : [...UsuariosReconocidos, element.idUsuario];
    setForm({ ...form, usuariosReconocidos: newUsuariosReconocidos });
    const resSel = listOfConsumers.item2.filter((el) => newUsuariosReconocidos.includes(el.idUsuario));
    setMembersSelected(resSel);
  };

  const excludeIdUsuarioOfOptions = (listOfConsumers?.item2 || []).filter((el) => el.idUsuario !== idUsuario);

  const filterDuplicated = excludeIdUsuarioOfOptions.filter(
    (el, index, array) => array.findIndex((element) => element.apellido === el.apellido) === index
  );

  const onLoad = () => {
    setLoaded(true);
  };

  const handleClick = (idTipoReconocimiento: number): void => {
    setForm({
      ...form,
      idTipoReconocimiento,
    });
  };

  return {
    typeOfRecognitionsQuery,
    listOfConsumers: filterDuplicated,
    membersSelected,
    dictionary,
    loaded,
    form,
    isModalOpen,
    isSendingRecognition,
    navigate,
    setIsSendingRecognition,
    setIsModalOpen,
    handleSelectMember,
    onLoad,
    handleClick,
    setForm,
    handleSubmit,
  };
};
