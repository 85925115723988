export interface IVoucher {
  cantidadTotalRegistros: number;
  cantidadPaginas: number;
  data: IVoucherData[];
  cantidadRegistros: number;
  pagina: number;
}

export interface IVoucherData {
  idCupon: number;
  titulo: string;
  imagenMiniatura: string;
  idTipoDescuento: VoucherIdTypeOfDiscount;
  tipoDescuentoNombre: string;
  descuento: number;
  precio: number;
  imagenGrande?: string;
  descripcion?: string;
}

export enum VoucherIdTypeOfDiscount {
  PORCENTAJE = 1,
  VALOR_FIJO = 2,
}

export interface IVoucherDictionary {
  title: string;
  totalResults: string;
  noResults: string;
  sortingListBy: IVoucherDictionarySortingListBy[];
  card: IVoucherCardDictionary;
  detail: IVoucherDetailDictionary;
  myVouchers: IMyVouchersDictionary;
  buttons: IVoucherDictionaryButtons;
  notifications: IVoucherDictionaryNotifications;
}

interface IVoucherCardDictionary {
  points: string;
}

interface IVoucherDictionaryNotifications {
  addedToCart: string;
}

interface IVoucherDictionaryButtons {
  redeem: string;
  getVouchers: string;
  download: string;
  sendToEmail: string;
}

interface IVoucherDetailDictionary {
  title: string;
  discount: string;
  fixedValue: string;
  points: string;
}

interface IMyVouchersDictionary {
  title: string;
  emptyList: string;
  getVouchers: string;
  idVoucherStatus: { [key: string]: string };
  imageQRAlt: string;
  imageAlt: string;
  priceText: string;
  statusText: string;
}

export interface IVoucherDictionarySortingListBy {
  name: string;
  queryParam: string;
}

export enum VoucherSortingOptions {
  MAYOR_PRECIO = 'precio desc',
  MENOR_PRECIO = 'precio asc',
  NOMBRE_ASCENDENTE = 'nombre asc',
  NOMBRE_DESCENDENTE = 'nombre desc',
}

export type VouchersDTOQueryParams = Pick<IVoucher, 'pagina'> & {
  ordenamiento: VoucherSortingOptions;
  cantidadRegistrosPorPagina: number;
  idioma?: string;
};

export interface VoucherByIdProps {
  idCupon: number;
  idioma?: string;
}

export type VoucherById = Pick<IVoucherData, 'idCupon' | 'idTipoDescuento' | 'tipoDescuentoNombre' | 'descuento'> & {
  nombre: string;
  descripcion: string;
  imagenGrande: string;
  puntos: number;
};

export type FormateadVoucher = Pick<
  IVoucherData,
  'idCupon' | 'titulo' | 'imagenMiniatura' | 'imagenGrande' | 'descripcion'
> & {
  descuentoText: string;
  puntos: string;
  pointsText: string;
  statusText: string;
};

export type FormateadExchangedVouchers = Pick<IVoucherData, 'idCupon' | 'titulo' | 'imagenMiniatura'> & {
  statusText: string;
};

export type ExchangedVouchers = Pick<
  IVoucher,
  'cantidadPaginas' | 'cantidadTotalRegistros' | 'cantidadRegistros' | 'pagina'
> & {
  data: ExchangedVoucherData[];
};

export interface IExchangedVoucherDetail {
  idCuponMovimiento: number;
  id: number;
  idCupon: number;
  idPersona: number;
  fechaAsignacion: Date;
  fechaCanje: Date | null;
  idCuponEstado: IdVoucherStatus;
  estadoDescripcion: string;
  codigo: string;
  imagenMiniatura: null;
  idCliente: number;
  cuponNombre: string;
  imagenQR: string;
  descripcion: string;
  tipoDescuento: ExchangedVoucherTypeOfDiscount;
  descuento: number;
  urlImagenQR: string | null;
}

export enum ExchangedVoucherTypeOfDiscount {
  PORCENTAJE = 'Porcentaje',
  VALOR_FIJO = 'Valor fijo',
}

export type FormateadExchangedVoucher = Pick<
  IExchangedVoucherDetail,
  'estadoDescripcion' | 'cuponNombre' | 'imagenQR' | 'tipoDescuento' | 'imagenMiniatura' | 'descripcion'
> & {
  descuentoTexto: string;
  precioTexto: string;
  estado: string;
  estadoTexto: string;
};

export interface ExchangedVoucherData {
  idCuponMovimiento: number;
  id: number;
  idCupon: number;
  idPersona: number;
  idCliente: number;
  idCuponEstado: IdVoucherStatus;
  fechaAsignacion: Date;
  fechaCanje: Date | null;
  estadoDescripcion: VoucherStatusDescription;
  codigo: string;
  cuponNombre: string;
  imagenMiniatura: string;
  imagenQR: string;
  descripcion: string;
  tipoDescuento: ExchangedVoucherTypeOfDiscount;
  descuento: number;
  urlImagenQR: null;
}

enum IdVoucherStatus {
  VIGENTE = 1,
  VENCIDO = 2,
  USADO = 3,
}

enum VoucherStatusDescription {
  VIGENTE = 'Vigente',
  VENCIDO = 'Vencido',
  USADO = 'Usado',
}
