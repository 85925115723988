import { useContext, useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import {
  IConsolidatedPersonActions,
  IDsTypeOFTransaction,
  IFormatPointsExchangeDetailData,
  IFormatTableColumns,
  ISortDirections,
  ITransactionColumns,
  ITransactionCryptoExchangeDetailFormateadData,
  ITransactionDetailFormateadData,
  ITransactionExcludeSortingColumns,
  ITransactionFormatTableRows,
  ITransactionPointsAssignmentDetailFormateadData,
  ITransactionsDictionary,
  ITransactionsSort,
  ITypeOfTransaction,
} from '../../common/types';
import useLanguage from '../../common/hooks/useLanguage';
import { LanguageContext } from '../../context';
import { useTransactionsPerson, useTypeOfTransaction } from '../../common/hooks';
import { CANTIDAD_REGISTROS_POR_PAGINA } from '../../common/constants/constants';
import {
  formatCryptoExchangeDetail,
  formatPointsAssignmentDetail,
  formatPointsExchangeDetail,
  formatRecognitionDetail,
  formatTableColumns,
  recentTransactionsFormatTableRows,
} from '../../utils';
import EN from '../../common/language/transactions/en.json';
import ES from '../../common/language/transactions/es.json';
import { formatTypeOfTransactions } from '../../utils/type_of_transactions/formateTypeOfTransactions';

export const useMyTransactions = () => {
  const [isViewDetailOpen, setIsViewDetailOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [typeOfTransaction, setTypeOfTransaction] = useState<number>(0);
  const [selectedTransaction, setSelectedTransaction] = useState<number>(0);
  const [sort, setSort] = useState<ITransactionsSort>({
    column: ITransactionColumns.fecha,
    direction: ISortDirections.desc,
  });
  const { language } = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: ITransactionsDictionary };

  const {
    consolidatedQuery: { data: transactionsOfConsolidatedPerson, isLoading: isLoadingTransactionsOfConsolidatedPerson },
    transactionDetailQuery: { data: transactionDetail },
  } = useTransactionsPerson({
    idMovimiento: selectedTransaction,
    transactionsPerson: {
      idioma: language,
      pagina: page,
      ordenamiento: `${sort.column} ${sort.direction}`,
      cantidadRegistrosPorPagina: CANTIDAD_REGISTROS_POR_PAGINA,
      idTipoMovimiento: typeOfTransaction,
    },
  });

  const {
    userTypeOfTransactionQuery: { data: userTypeOfTransaction },
  } = useTypeOfTransaction({ isFetchingUserTypeOfTransaction: true });

  const changeSort = (column: string): void => {
    const getColumn = ITransactionColumns[column];
    const getDirection = sort.direction === ISortDirections.asc ? ISortDirections.desc : ISortDirections.asc;

    setSort({
      column: getColumn,
      direction: getDirection,
    });
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number): void => {
    event.preventDefault();
    setPage(value);
  };

  const handleViewDetail = (idMovimiento: number): void => {
    setSelectedTransaction(idMovimiento);
    setIsViewDetailOpen(!isViewDetailOpen);
  };

  const myTransactionsColumns: IFormatTableColumns[] = formatTableColumns(dictionary.table.column, [
    ITransactionExcludeSortingColumns.comentario,
  ]);

  const exchangePointsDetailFormateadData: IFormatPointsExchangeDetailData = formatPointsExchangeDetail(
    transactionDetail,
    dictionary
  );

  const recognitionDetailFormateadData: ITransactionDetailFormateadData = formatRecognitionDetail(transactionDetail);

  const pointsAssignmentDetailFormateadData: ITransactionPointsAssignmentDetailFormateadData =
    formatPointsAssignmentDetail(transactionDetail, dictionary);

  const cryptoExchangeDetailFormateadData: ITransactionCryptoExchangeDetailFormateadData = formatCryptoExchangeDetail(
    transactionDetail,
    dictionary
  );

  const typeOfTransactionMenuFormateadData: ITypeOfTransaction[] = formatTypeOfTransactions(
    userTypeOfTransaction,
    dictionary
  );

  const viewDetail: IConsolidatedPersonActions[] = transactionsOfConsolidatedPerson?.data.map(({ idMovimiento }) => {
    const detalle: React.ReactNode = (
      <Chip
        onClick={() => handleViewDetail(idMovimiento)}
        color="primary"
        label={dictionary.table.actions.detail.title}
      />
    );

    return {
      idMovimiento,
      detalle,
    };
  });

  const myTransactionsRows: ITransactionFormatTableRows[] = recentTransactionsFormatTableRows(
    transactionsOfConsolidatedPerson?.data ?? [],
    viewDetail
  );

  const isProductsPointsExchangeDetail: boolean = Boolean(
    transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.canjePuntos &&
      transactionDetail?.canjePuntos &&
      !transactionDetail?.canjePuntos?.cupones
  );

  //* asignación de puntos y reconocimiento comparten el mismo idTipoMovimiento
  const isRecognitionDetail: boolean = Boolean(
    transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.asignacionPuntos && transactionDetail?.reconocimiento
  );

  const isPointsAssignmentDetail: boolean = Boolean(
    (transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.asignacionPuntos ||
      transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.ajusteResta) &&
      transactionDetail?.asignacionDePuntos
  );

  const isCryptoExchangeDetail: boolean = Boolean(
    (transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.canjePuntosPorCrypto ||
      transactionDetail?.idTipoMovimiento === IDsTypeOFTransaction.compraDePuntosConCrypto) &&
      transactionDetail?.crypto
  );

  useEffect(() => {
    if (transactionsOfConsolidatedPerson?.cantidadPaginas) {
      setTotalPages(transactionsOfConsolidatedPerson.cantidadPaginas);
      return;
    }
  }, [transactionsOfConsolidatedPerson?.cantidadPaginas, setTotalPages]);

  return {
    isLoading: isLoadingTransactionsOfConsolidatedPerson,
    myTransactionsColumns,
    myTransactionsRows,
    page,
    totalPages,
    sort,
    dictionary,
    typeOfTransaction,
    isViewDetailOpen,
    transactionDetail,
    exchangePointsDetailFormateadData,
    recognitionDetailFormateadData,
    isProductsPointsExchangeDetail,
    isRecognitionDetail,
    isPointsAssignmentDetail,
    pointsAssignmentDetailFormateadData,
    isCryptoExchangeDetail,
    cryptoExchangeDetailFormateadData,
    typeOfTransactionMenuFormateadData,
    setIsViewDetailOpen,
    setPage,
    setTypeOfTransaction,
    changeSort,
    handleChangePage,
  };
};
