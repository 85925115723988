import { apiUrlRecognitions } from '../../../common/types';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';

export const getMyRecognitions = async (queryParams: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(apiUrlRecognitions.recognitionReceived, queryParams);

  try {
    const response: IRecognitions = await fetchContent(URL);
    return response;
  } catch (error: any) {
    console.error('[getMyRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
