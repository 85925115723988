import { API_VOUCHERS_URL } from '../../common/constants';
import { IExchangedVoucherDetail } from '../../common/types/voucher';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getExchangedVoucher = async (idCuponMovimiento: number): Promise<IExchangedVoucherDetail> => {
  const URL: string = API_VOUCHERS_URL.exchangedVoucher.replace('{idCuponMovimiento}', String(idCuponMovimiento));

  try {
    const exchangedVoucher: IExchangedVoucherDetail = await fetchContent(URL);
    return exchangedVoucher;
  } catch (error: any) {
    console.error('[getExchangedVoucher]: ' + error);
    throw handleServiceError(error);
  }
};
