import { useMutation } from '@tanstack/react-query';
import { IPersonAddressList, PostAddressProps } from '../../types';
import { modalError, modalSuccess } from '../../../utils';
import { postAddresses } from '../../../services/person';

interface PersonMutationProps {
  postAddress?: PostAddressProps;
}

export const usePersonMutation = () => {
  const postAddressMutation = useMutation({
    mutationFn: async ({ postAddress }: PersonMutationProps): Promise<IPersonAddressList> =>
      await postAddresses(postAddress.form),

    onSuccess(_, variables) {
      const {
        postAddress: { successText },
      } = variables;

      modalSuccess(successText);
    },

    onError(_, variables) {
      const {
        postAddress: { errorText },
      } = variables;

      modalError(errorText);
    },
  });

  return { postAddressMutation };
};
