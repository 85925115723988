import { useRecognitions } from '../../../hooks/recognitions';
import { Box, Typography } from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import './recognitions.css';
import { AddComment, RecognitionModal, SelectMembers, TypesOfRecognitions } from '..';

export const Recognitions = () => {
  const {
    typeOfRecognitionsQuery,
    listOfConsumers,
    membersSelected,
    dictionary,
    loaded,
    form,
    isModalOpen,
    isSendingRecognition,
    navigate,
    setIsSendingRecognition,
    handleSubmit,
    setIsModalOpen,
    onLoad,
    handleClick,
    handleSelectMember,
    setForm,
  } = useRecognitions();

  return (
    <Box p={'20px'}>
      <Typography
        variant="h4"
        color="primary"
      >
        {dictionary.title}
        <ShortLine position="left" />
      </Typography>
      <Box className="recognitions-form-section">
        <Box
          border={2}
          borderColor={'primary.main'}
          borderRadius={2}
          padding={3}
          maxWidth={'1300px'}
          width={'100%'}
        >
          <SelectMembers
            listOfConsumers={listOfConsumers}
            membersSelected={membersSelected}
            dictionary={dictionary}
            handleSelectMember={handleSelectMember}
          />
          <TypesOfRecognitions
            form={form}
            typeOfRecognitions={typeOfRecognitionsQuery?.data}
            dictionary={dictionary}
            loaded={loaded}
            handleClick={handleClick}
            onLoad={onLoad}
          />
          <AddComment
            isSendingRecognition={isSendingRecognition}
            dictionary={dictionary}
            form={form}
            setForm={setForm}
            handleSubmit={handleSubmit}
            setIsSendingRecognition={setIsSendingRecognition}
          />
          <RecognitionModal
            dictionary={dictionary}
            navigate={navigate}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </Box>
      </Box>
    </Box>
  );
};
