import { CSSProperties } from 'styled-components';

export const VOUCHER_DETAIL_STYLES = {
  mainContainer: {
    padding: '30px 20px',
  } as CSSProperties,
  image: {
    maxWidth: '750px',
    maxHeight: '18rem',
    width: '95%',
    borderRadius: '10px',
  } as CSSProperties,
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    gap: '25px',
  } as CSSProperties,
  titleContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  } as CSSProperties,
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as CSSProperties,
};
