import { CSSProperties } from 'styled-components';

export const CENTER_NAVBAR_STYLES = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    minWidth: '40%',
  } as CSSProperties,
  link: {
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  } as CSSProperties,
  icon: {
    fontSize: '20px',
  },
};
