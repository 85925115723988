import { API_VOUCHERS_URL } from '../../common/constants';
import { VoucherById, VoucherByIdProps } from '../../common/types/voucher';
import { setQueryParams } from '../../utils';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getVoucher = async ({ idCupon, idioma }: VoucherByIdProps): Promise<VoucherById> => {
  let URL: string = API_VOUCHERS_URL.voucher.replace('{idCupon}', String(idCupon));
  URL = setQueryParams(URL, { idioma });

  try {
    const voucher: VoucherById = await fetchContent(URL);
    return voucher;
  } catch (error: any) {
    console.error('[getVoucher]: ' + error);
    throw handleServiceError(error);
  }
};
