import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Divider, FormControl, Grid } from '@mui/material';
import InputForm from '../../../../components/Inputs/InputForm/InputForm';
import './DatosDeLaCuentaForm.css';
import { AppContext } from '../../../../context/AppContext';
import { PutChangePass } from '../../../../services/MisDatosService';
import { useEffect, useState } from 'react';
import { getUsuarioById } from '../../../../services/UsuarioService';
import { Usuario } from '../../../../common/interfaces/ComunInterface';
import useLanguage from '../../../../common/hooks/useLanguage';
import ES from '../../../../common/language/users/es.json';
import EN from '../../../../common/language/users/en.json';
import { formMisDatosInterface } from '../../../../common/interfaces/MisDatosInterface';
import { ApprovedRecognitions } from '../../../Recognitions';
import { ViewTable } from '../../../../components/ViewTable/ViewTable';
import { IdFormaEntrega, ISortDirections, RecognitionsColumns } from '../../../../common/types';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import { usePerson } from '../../../../common/hooks';

type Props = {
  form: formMisDatosInterface | null;
  setForm: React.Dispatch<React.SetStateAction<any>>;
};

export const DatosDeLasDirecciones: React.FC<Props> = ({ form, setForm }) => {
  const { dictionary } = useLanguage({ EN, ES });

  // const handleSubmit = async () => {
  //   let res = await PutChangePass(form, appContext?.user);
  //   res && appContext?.setUser(res);
  // };

  return (
    <div>
      {form && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">{dictionary?.perfil.datosDirecciones}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider sx={{ marginBottom: '20px' }} />
            <CustomTable
              changeSort={() => {}}
              totalPages={0}
              isLoading={false}
              columns={[]}
              handleChangePage={() => {}}
              noResults=""
              data={[]}
              sort={{
                column: RecognitionsColumns.fecha,
                direction: ISortDirections.desc,
              }}
              page={1}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
