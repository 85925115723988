import { CSSProperties } from 'styled-components';

export const GET_VOUCHERS_STYLES = {
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
  voucherIcon: {
    fontSize: '150px',
  } as CSSProperties,
};
