import React from 'react';
import { NoResults } from '../../components/NoResults/NoResults';
import { Vouchers } from './components/Vouchers/Vouchers';
import { useVouchersCatalog } from '../../hooks/voucher';
import { VoucherCardContainer } from './components/VoucherCard/VoucherCardContainer';

export const VouchersCatalog = React.memo(() => {
  const {
    dictionary,
    currentSorting,
    vouchers,
    isLoading,
    isSelectedOption,
    page,
    formateadVouchers,
    isError,
    isVouchers,
    handleSelectOption,
    setIsSelectedOption,
    handleNavigate,
    handleChangePage,
  } = useVouchersCatalog();

  return (
    <Vouchers
      title={dictionary.title}
      cantidadTotalRegistros={vouchers?.cantidadTotalRegistros}
      totalResultsText={dictionary.totalResults}
      currentSorting={currentSorting}
      isLoading={isLoading}
      isError={isError}
      isSelectedOption={isSelectedOption}
      sortingListBy={dictionary.sortingListBy}
      isVouchers={isVouchers}
      noDataChildren={
        <NoResults
          text={dictionary.noResults}
          {...{ color: 'primary' }}
        />
      }
      voucherCard={
        <VoucherCardContainer
          formateadVouchers={formateadVouchers}
          onClick={handleNavigate}
        />
      }
      page={page}
      totalPages={vouchers?.cantidadPaginas}
      handleSelectOption={handleSelectOption}
      setIsSelectedOption={setIsSelectedOption}
      handleChangePage={handleChangePage}
    />
  );
});
