import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IVoucherDictionarySortingListBy } from '../../../../common/types/voucher';
import { useThemeContext } from '../../../../common/hooks';
import { SORTING_LIST_STYLES } from './styles';

interface SortingListProps {
  currentSorting: string;
  isSelectedOption: boolean;
  sortingOptions: IVoucherDictionarySortingListBy[];
  handleSelectOption: (event: SelectChangeEvent<string>) => void;
  setIsSelectedOption: (option: boolean) => void;
}

export const SortingList: React.FC<SortingListProps> = ({
  currentSorting,
  sortingOptions,
  isSelectedOption,
  setIsSelectedOption,
  handleSelectOption,
}) => {
  const { theme } = useThemeContext();

  return (
    <Select
      labelId="voucher-sorting-list-label"
      id="voucher-sorting-list"
      onFocus={() => setIsSelectedOption(true)}
      onBlur={() => setIsSelectedOption(false)}
      onChange={handleSelectOption}
      value={currentSorting}
      IconComponent={() => (isSelectedOption ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      sx={SORTING_LIST_STYLES.select}
    >
      {sortingOptions?.map(({ name, queryParam }) => {
        return (
          <MenuItem
            key={name}
            value={queryParam}
            sx={SORTING_LIST_STYLES.selectOption(
              queryParam,
              currentSorting,
              theme[0]?.color,
              'var(--color-light-gray)'
            )}
            divider
          >
            {name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
