import React from 'react';
import { Box, Pagination, PaginationItem, Stack, SxProps } from '@mui/material';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { useThemeContext } from '../../common/hooks';
import { CUSTOM_PAGINATION_STYLES } from './styles';

interface CustomPaginationProps {
  page: number;
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  sx?: SxProps<Theme>;
}

export const CustomPagination: React.FC<CustomPaginationProps> = ({ page, count, onChange, sx = {} }) => {
  const { theme } = useThemeContext();

  return (
    <Box sx={{ ...CUSTOM_PAGINATION_STYLES.mainContainer, ...sx }}>
      <Stack spacing={2}>
        <Pagination
          color="primary"
          shape="rounded"
          variant="outlined"
          page={page > 0 ? page : 1}
          count={count}
          onChange={onChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              sx={CUSTOM_PAGINATION_STYLES.paginationItem(theme[10]?.color ?? 'primary')}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
