import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  RemoveCircleOutlined as RemoveCircleOutlinedIcon,
} from '@mui/icons-material';
import { IPersonAddressList } from '../../../../common/types';
import { IFormCarrito } from '../../../../common/interfaces/CarritoInterface';
import { SELECT_ADDRESS_LIST_STYLES } from './styles';

interface AddressesListProps {
  address: string;
  selectOptionText: string;
  form: IFormCarrito;
  addressesList: IPersonAddressList[];
  newAddressBtnText: string;
  isAddingNewAddress: boolean;
  setForm: React.Dispatch<React.SetStateAction<IFormCarrito>>;
  handleSelectAddress: (
    value: string,
    form: IFormCarrito,
    setForm: React.Dispatch<React.SetStateAction<IFormCarrito>>
  ) => void;
  setIsAddingNewAddress: (isAddingNewAddress: boolean) => void;
}

export const SelectAddressesList: React.FC<AddressesListProps> = ({
  address,
  selectOptionText,
  form,
  addressesList,
  newAddressBtnText,
  isAddingNewAddress,
  setForm,
  handleSelectAddress,
  setIsAddingNewAddress,
}) => {
  return (
    <>
      <Box sx={SELECT_ADDRESS_LIST_STYLES.buttonContainer}>
        <Typography
          variant="h6"
          color="initial"
          aria-label="address-title"
        >
          {address}
        </Typography>

        <Button
          aria-label="add-new-address-button"
          type="button"
          variant="outlined"
          size="small"
          startIcon={
            isAddingNewAddress ? <RemoveCircleOutlinedIcon color="error" /> : <AddCircleOutlinedIcon color="success" />
          }
          onClick={() => setIsAddingNewAddress(!isAddingNewAddress)}
        >
          {newAddressBtnText}
        </Button>
      </Box>

      {!isAddingNewAddress && (
        <select
          aria-label="address-select"
          className="select-carrito"
          onChange={({ target: { value } }) => handleSelectAddress(value, form, setForm)}
        >
          <option
            defaultValue={-1}
            disabled
            value={-1}
            aria-label="address-default-option"
          >
            {selectOptionText}
          </option>

          {addressesList?.map(
            ({
              id,
              idPais,
              idProvincia,
              idLocalidad,
              calle,
              altura,
              piso,
              departamento,
              idLocalidadNavigation: {
                nombre: localidad,
                provincia: { nombre: provincia },
              },
            }) => (
              <option
                aria-label={`address-option-${id}`}
                key={id}
                value={`${idPais},${idProvincia},${idLocalidad},${calle} ${altura} ${piso ?? ''} ${
                  departamento ?? ''
                } ${localidad ?? ''} ${provincia ?? ''}`}
              >
                {`${calle} ${altura} ${piso ?? ''} ${departamento ?? ''} ${localidad ?? ''} ${provincia ?? ''}`.trim()}
              </option>
            )
          )}
        </select>
      )}
    </>
  );
};
