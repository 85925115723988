import React from 'react';
import { GetVouchers } from '../../components/GetVouchers/GetVouchers';
import { Vouchers } from '../../components/Vouchers/Vouchers';
import { useMyVouchers } from '../../../../hooks/voucher';
import { VoucherCardContainer } from '../../components/VoucherCard/VoucherCardContainer';
import { VoucherDetailModal } from '../../components/VoucherDetailModal/VoucherDetailModal';

export const MyVouchers = React.memo(() => {
  const {
    dictionary,
    currentSorting,
    vouchers,
    formateadVouchers,
    page,
    formateadExchangedVoucher,
    isLoading,
    isSelectedOption,
    isError,
    isVouchers,
    isViewDetailOpen,
    setIsViewDetailOpen,
    handleChangePage,
    handleSelectOption,
    setIsSelectedOption,
    handleViewDetail,
    handleOnPrint,
  } = useMyVouchers();

  return (
    <>
      <Vouchers
        title={dictionary.myVouchers.title}
        cantidadTotalRegistros={vouchers?.cantidadTotalRegistros}
        totalResultsText={dictionary.totalResults}
        currentSorting={currentSorting}
        isLoading={isLoading}
        isError={isError}
        isSelectedOption={isSelectedOption}
        sortingListBy={dictionary.sortingListBy}
        isVouchers={isVouchers}
        noDataChildren={
          <GetVouchers
            emptyListText={dictionary.myVouchers.emptyList}
            getVouchersText={dictionary.myVouchers.getVouchers}
            getVouchersBtnText={dictionary.buttons.getVouchers}
          />
        }
        voucherCard={
          <VoucherCardContainer
            formateadVouchers={formateadVouchers}
            onClick={handleViewDetail}
          />
        }
        page={page}
        totalPages={vouchers?.cantidadPaginas}
        handleSelectOption={handleSelectOption}
        setIsSelectedOption={setIsSelectedOption}
        handleChangePage={handleChangePage}
      />

      {Boolean(formateadExchangedVoucher && isViewDetailOpen) && (
        <VoucherDetailModal
          title={formateadExchangedVoucher?.cuponNombre}
          description={formateadExchangedVoucher?.descripcion}
          qrCode={formateadExchangedVoucher?.imagenQR}
          image={formateadExchangedVoucher?.imagenMiniatura}
          qrCodeAlt={dictionary.myVouchers.imageQRAlt}
          imageAlt={dictionary.myVouchers.imageAlt}
          downloadButtonText={dictionary.buttons.download}
          emailButtonText={dictionary.buttons.sendToEmail}
          priceText={formateadExchangedVoucher?.precioTexto}
          discount={formateadExchangedVoucher?.descuentoTexto}
          status={formateadExchangedVoucher?.estado}
          statusText={formateadExchangedVoucher?.estadoTexto}
          isModalOpen={isViewDetailOpen}
          handleOnPrint={handleOnPrint}
          setIsModalOpen={setIsViewDetailOpen}
        />
      )}
    </>
  );
});
