import { CSSProperties } from 'styled-components';

export const VOUCHER_STYLES = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px',
  } as CSSProperties,
  voucherContainer: {
    borderColor: 'primary.main',
    display: 'flex',
    justifyContent: 'space-between',
  } as CSSProperties,
  title: {
    fontSize: '30px',
  } as CSSProperties,
  sortingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  } as CSSProperties,
  sortingTitle: {
    color: 'var(--color-light-gray)',
    fontSize: '18px',
    pr: 1,
  } as CSSProperties,
  divider: {
    height: '20px',
  } as CSSProperties,
};
