import { apiUrlCountry, Country } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getCountries = async (): Promise<Country[]> => {
  const URL: string = apiUrlCountry.countries;

  try {
    const countries: Country[] = await fetchContent(URL);

    return countries;
  } catch (error: any) {
    console.error('[getCountries]: ' + error);
    throw handleServiceError(error);
  }
};
