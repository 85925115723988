import { formatCommonTransactionDetail } from '..';
import { formatNumber, formatTableSimpleColumns } from '..';
import { IFormatPointsExchangeDetailData, ITransactionDetail, ITransactionsDictionary } from '../../common/types';

//* Usar solo para formatear productos
export const formatPointsExchangeDetail = (
  transaction: ITransactionDetail,
  dictionary: ITransactionsDictionary
): IFormatPointsExchangeDetailData => {
  if (!transaction?.canjePuntos) return null;

  const { date, points } = formatCommonTransactionDetail(transaction?.fecha, transaction?.canjePuntos?.totalPuntos);

  const productsFormatead =
    transaction?.canjePuntos?.productos?.map((row) => {
      return {
        id: row?.id,
        producto: row?.producto,
        precio: formatNumber(row?.precio) || '-',
        puntos: row?.esGiftCard ? formatNumber(row?.puntos) || '-' : '-',
        cantidad: formatNumber(row?.cantidad) || '-',
      };
    }) || [];
  const formateadTransaction: IFormatPointsExchangeDetailData = {
    date,
    points,
    productsTableHeaders: formatTableSimpleColumns(dictionary.detailModal.pointsExchange.productsTable),
    products: productsFormatead,
  };

  return formateadTransaction;
};
