import {
  FormateadVoucher,
  IVoucherData,
  IVoucherDictionary,
  VoucherById,
  VoucherIdTypeOfDiscount,
} from '../../common/types/voucher';
import { formatNumber, getValueFromObject } from '../functions';

export const formatVouchers = (
  vouchers: IVoucherData[] | VoucherById[],
  dictionary: IVoucherDictionary
): FormateadVoucher[] => {
  const formateadVouchers: FormateadVoucher[] = vouchers?.map((voucher) => {
    const haveAPercentageDiscount: boolean = Boolean(voucher?.idTipoDescuento === VoucherIdTypeOfDiscount.PORCENTAJE);

    return {
      idCupon: voucher?.idCupon,
      titulo: voucher?.titulo || voucher?.nombre,
      imagenMiniatura: voucher?.imagenMiniatura || null,
      imagenGrande: voucher?.imagenGrande || null,
      descuentoText: haveAPercentageDiscount
        ? dictionary.detail.discount.replace('{value}', formatNumber(voucher?.descuento))
        : dictionary.detail.fixedValue.replace('{value}', formatNumber(voucher?.descuento)),
      descripcion: voucher?.descripcion || null,
      puntos: dictionary.detail.points.replace('{points}', formatNumber(voucher?.puntos)),
      pointsText: dictionary.card.points.replace('{points}', formatNumber(voucher?.precio)) || null,
      statusText: getValueFromObject(dictionary.myVouchers.idVoucherStatus, voucher?.idCuponEstado) || null,
    };
  });

  return formateadVouchers;
};
