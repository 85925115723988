import { useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import useLanguage from '../../common/hooks/useLanguage';
import { IVoucherDictionary, VoucherSortingOptions } from '../../common/types/voucher';
import ES from '../../common/language/voucher/es.json';
import EN from '../../common/language/voucher/en.json';

export const useVouchers = () => {
  const { dictionary } = useLanguage({ EN, ES }) as { dictionary: IVoucherDictionary };
  const [currentSorting, setCurrentSorting] = useState<VoucherSortingOptions>(VoucherSortingOptions.NOMBRE_ASCENDENTE);
  const [page, setPage] = useState<number>(1);
  const [isSelectedOption, setIsSelectedOption] = useState<boolean>(false);

  const handleSelectOption = useCallback((event: SelectChangeEvent<string>): void => {
    setIsSelectedOption(false);
    setCurrentSorting(event.target.value as VoucherSortingOptions);
  }, []);

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, value: number): void => {
    event.preventDefault();
    setPage(value);
  }, []);

  return {
    dictionary,
    currentSorting,
    page,
    isSelectedOption,
    handleSelectOption,
    setIsSelectedOption,
    handleChangePage,
  };
};
