import React from 'react';
import { Box, Divider, Grid, SelectChangeEvent, Typography } from '@mui/material';
import ShortLine from '../../../../components/ShortLine/ShortLine';
import { SortingList } from '../SortingList/SortingList';
import { IVoucherDictionarySortingListBy } from '../../../../common/types/voucher';
import MiniLoader from '../../../../components/Loaders/MiniLoader/MiniLoader';
import { CustomPagination } from '../../../../components/CustomPagination/CustomPagination';
import { VOUCHER_STYLES } from './styles';

interface VoucherProps {
  title: string;
  cantidadTotalRegistros: number;
  totalResultsText: string;
  currentSorting: string;
  sortingListBy: IVoucherDictionarySortingListBy[];
  isLoading: boolean;
  isVouchers: boolean;
  isError: boolean;
  isSelectedOption: boolean;
  voucherCard: React.ReactNode;
  noDataChildren: React.ReactNode;
  page: number;
  totalPages: number;
  handleChangePage(event: React.ChangeEvent<unknown>, value: number): void;
  setIsSelectedOption: (option: boolean) => void;
  handleSelectOption: (event: SelectChangeEvent<string>) => void;
}

export const Vouchers: React.FC<VoucherProps> = ({
  title,
  cantidadTotalRegistros,
  totalResultsText,
  currentSorting,
  sortingListBy,
  isLoading,
  isVouchers,
  isError,
  isSelectedOption,
  voucherCard,
  noDataChildren,
  page,
  totalPages,
  handleChangePage,
  setIsSelectedOption,
  handleSelectOption,
}) => {
  return (
    <Box sx={VOUCHER_STYLES.mainContainer}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            sx={VOUCHER_STYLES.voucherContainer}
            borderBottom={isVouchers ? 2 : 0}
          >
            <Grid item>
              <Typography
                variant="h4"
                sx={VOUCHER_STYLES.title}
                fontWeight={700}
                color="primary"
              >
                {title}
              </Typography>
            </Grid>

            {!isVouchers && <ShortLine position="left" />}

            {isVouchers && (
              <Grid
                item
                md={6}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'right'}
              >
                <Box sx={VOUCHER_STYLES.sortingContainer}>
                  <Typography
                    color="initial"
                    fontWeight={400}
                    sx={VOUCHER_STYLES.sortingTitle}
                  >
                    {`${cantidadTotalRegistros} ${totalResultsText}`}
                  </Typography>

                  <Divider
                    orientation="vertical"
                    sx={VOUCHER_STYLES.divider}
                  />

                  <SortingList
                    currentSorting={currentSorting}
                    isSelectedOption={isSelectedOption}
                    sortingOptions={sortingListBy}
                    handleSelectOption={handleSelectOption}
                    setIsSelectedOption={setIsSelectedOption}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          {isLoading ? <MiniLoader /> : !isVouchers || isError ? noDataChildren : voucherCard}
        </Grid>
      </Grid>
      {isVouchers && (
        <CustomPagination
          page={page}
          count={totalPages}
          onChange={handleChangePage}
        />
      )}
    </Box>
  );
};
