import { PermissionCode, Route, SimpleRouteMenu } from '../../types';

export const VOUCHER_SUB_MENU: SimpleRouteMenu[] = [
  {
    ruta: Route.VOUCHERS,
    dictionario: 'catalogoDeCupones',
    codigo: PermissionCode.VOUCHERS,
  },
  {
    ruta: Route.MY_VOUCHERS,
    dictionario: 'misCupones',
    codigo: PermissionCode.VOUCHERS,
  },
];
