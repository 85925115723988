import React from 'react';
import { Box, Typography, Modal, Button, Grid } from '@mui/material/';
import { Close as CloseIcon } from '@mui/icons-material';
import { TermsAndConditionsProps } from '../../../../common/types';
import { STYLE_TERMS_AND_CONDITIONS } from './style';

interface Props {
  termsAndConditions: TermsAndConditionsProps;
}
export const TermsAndConditions: React.FC<Props> = ({
  termsAndConditions: { terms, title, image, isOpen, setIsOpen },
}) => {
  return (
    <Grid
      item
      sm={12}
      md={5}
    >
      <img
        src={image}
        style={STYLE_TERMS_AND_CONDITIONS.imgProductDetail}
        alt="img-producto-detalle"
      />
      {terms && (
        <>
          <Typography
            fontSize={16}
            color="primary"
            sx={{ ...STYLE_TERMS_AND_CONDITIONS.title, ...STYLE_TERMS_AND_CONDITIONS.titleHover() }}
            onClick={() => setIsOpen(true)}
            width="fit-content"
          >
            {title}
          </Typography>
          <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={STYLE_TERMS_AND_CONDITIONS.termsModalBox}>
              <Box style={STYLE_TERMS_AND_CONDITIONS.termsModalOnCloseBtnBox}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setIsOpen(false)}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <Typography
                variant="h6"
                component="h2"
                sx={STYLE_TERMS_AND_CONDITIONS.termsModalTitle}
              >
                {title}
              </Typography>
              <Typography
                variant="h6"
                component="h3"
                sx={STYLE_TERMS_AND_CONDITIONS.termsModalTermsDescription}
              >
                {terms}
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </Grid>
  );
};
