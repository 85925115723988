import { headerOptions } from '../../common/constants';
import { apiUrlPerson, IPersonAddressAddDTO, IPersonAddressList } from '../../common/types';
import { fetchContent } from '../../utils';
import { handleServiceError } from '../GiftCardService';

export const postAddresses = async (address: IPersonAddressAddDTO): Promise<IPersonAddressList> => {
  const URL: string = apiUrlPerson.ADDRESS;

  try {
    const user: IPersonAddressList = await fetchContent(URL, {
      method: 'POST',
      body: JSON.stringify(address),
      headers: headerOptions,
    });
    return user;
  } catch (error: any) {
    console.error('[postAddresses]: ' + error);
    throw handleServiceError(error);
  }
};
