import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const modalError = (msj: string, tiempo?: number) => {
  Toast.fire({
    icon: 'error',
    title: msj,
    timer: tiempo || 4000,
    width: 'auto',
  });
};

export const modalSuccess = (msj: string, tiempo?: number) => {
  Toast.fire({
    icon: 'success',
    title: msj,
    timer: tiempo || 6000,
    width: 'auto',
  });
};
