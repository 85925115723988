import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { bodyProductsInterface } from '../../../common/interfaces/GiftCardInterfaces';
import { ThemeContext } from '../../../context';

type Props = {
  cantidadPaginas: number | null | undefined;
  filterProducts: bodyProductsInterface;
  setFilterProducts: React.ComponentState;
};

//TODO REEMPLAZAR POR CustomPagination
export const CatalogoPagination: React.FC<Props> = ({ cantidadPaginas, filterProducts, setFilterProducts }) => {
  const [page, setPage] = useState<number>(1);
  const theme = React.useContext(ThemeContext);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setFilterProducts({ ...filterProducts, pagina: value });
    setPage(value);
  };

  useEffect(() => {
    if (filterProducts.pagina !== page) {
      setPage(filterProducts.pagina);
    }
  }, [filterProducts.pagina, page]);

  return (
    <Stack spacing={2}>
      <Pagination
        shape="rounded"
        siblingCount={0}
        page={page}
        onChange={handleChange}
        count={cantidadPaginas ? cantidadPaginas : 1}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
            sx={{
              '&.Mui-selected': {
                backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                color: 'white', // Color del texto para el número de página seleccionado
              },
              border: `1px solid ${theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary'}`,
              color: 'secondary',
            }}
            selected={item.page === page}
          />
        )}
      />
    </Stack>
  );
};
