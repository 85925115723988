import {
  ExchangedVoucherTypeOfDiscount,
  FormateadExchangedVoucher,
  IExchangedVoucherDetail,
  IVoucherDictionary,
} from '../../common/types/voucher';
import { formatNumber, getValueFromObject } from '../functions';

export const formatExchangedVoucher = (
  voucher: IExchangedVoucherDetail,
  dictionary: IVoucherDictionary
): FormateadExchangedVoucher => {
  const haveAPercentageDiscount: boolean = Boolean(
    voucher?.tipoDescuento === ExchangedVoucherTypeOfDiscount.PORCENTAJE
  );

  return {
    cuponNombre: voucher?.cuponNombre,
    descuentoTexto: haveAPercentageDiscount
      ? dictionary.detail.discount.replace('{value}', formatNumber(voucher?.descuento))
      : dictionary.detail.fixedValue.replace('{value}', formatNumber(voucher?.descuento)),
    precioTexto: dictionary.myVouchers.priceText,
    estado: getValueFromObject(dictionary.myVouchers.idVoucherStatus, voucher?.idCuponEstado) || null,
    estadoTexto: dictionary.myVouchers.statusText,
    estadoDescripcion: getValueFromObject(dictionary.myVouchers.idVoucherStatus, voucher?.idCuponEstado) || null,
    imagenQR: voucher?.imagenQR,
    tipoDescuento: voucher?.tipoDescuento,
    imagenMiniatura: voucher?.imagenMiniatura,
    descripcion: voucher?.descripcion?.length > 400 ? voucher.descripcion.slice(0, 400) + '...' : voucher.descripcion,
  };
};
