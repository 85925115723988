import { API_VOUCHERS_URL } from '../../common/constants';
import { IVoucher, VouchersDTOQueryParams } from '../../common/types/voucher';
import { setQueryParams } from '../../utils';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getVouchers = async ({
  ordenamiento,
  pagina,
  cantidadRegistrosPorPagina,
  idioma,
}: VouchersDTOQueryParams): Promise<IVoucher> => {
  const URL: string = setQueryParams(API_VOUCHERS_URL.vouchers, {
    idioma,
    pagina,
    cantidadRegistrosPorPagina,
    ordenamiento,
  });

  try {
    const voucher: IVoucher = await fetchContent(URL);
    return voucher;
  } catch (error: any) {
    console.error('[getVouchers]: ' + error);
    throw handleServiceError(error);
  }
};
