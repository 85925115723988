import { useState } from 'react';
import Swal from 'sweetalert2';
import { useAppContext, useCity, useCountry, usePersonMutation, useStateAPI } from '../../../common/hooks';
import { validarDomicilio } from '../../../utils/validaciones';

interface NewAddressFormProps {
  saveBtnText: string;
  noSaveBtnText: string;
  cancelBtnText: string;
  guardarDireccion: string;
  guardarDireccionBody: string;
  successText: string;
  errorText: string;
  form: any;
  setForm: any;
  setFormErr: any;
}
export const useNewAddressForm = ({
  cancelBtnText,
  noSaveBtnText,
  saveBtnText,
  guardarDireccion,
  guardarDireccionBody,
  successText,
  errorText,
  form,
  setForm,
  setFormErr,
}: NewAddressFormProps) => {
  const [isCreatingAddress, setIsCreatingAddress] = useState<boolean>(false);
  const { postAddressMutation } = usePersonMutation();
  const { idPersona } = useAppContext();

  const {
    countriesQuery: { data: countries },
  } = useCountry({ isFetchingCountries: true });

  const {
    statesQuery: { data: states },
  } = useStateAPI({ isFetchingStates: true });

  const {
    citiesQuery: { data: cities },
  } = useCity({
    citiesByIdProvincia: {
      idProvincia: form.idProvincia,
    },
  });

  const handleOnSubmitForm = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const domicilio: string = `${form.calle} ${form.calleNumero} ${form.piso ?? ''} ${form.departamento ?? ''}`.trim();

    setForm({
      ...form,
      domicilio,
    });

    if (!validarDomicilio(form)) {
      setFormErr(true);
      return;
    }

    Swal.fire({
      title: guardarDireccion,
      text: guardarDireccionBody.replace('{address}', domicilio),
      icon: 'question',
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: saveBtnText,
      denyButtonText: noSaveBtnText,
      cancelButtonText: cancelBtnText,
      inputAttributes: {
        autocapitalize: 'off',
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await postAddressMutation.mutateAsync({
            postAddress: {
              form: {
                idPersona,
                idPais: form.idPais,
                idProvincia: form.idProvincia,
                idLocalidad: form.idLocalidad,
                codigoPostal: form.codigoPostal,
                esPrincipal: false,
                calle: form.calle,
                altura: form.calleNumero,
                piso: form.piso,
                departamento: form.departamento,
              },
              successText,
              errorText,
            },
          });

          setIsCreatingAddress(true);
        } catch (error) {
          console.log(error);
        }
      },
      preDeny: () => setIsCreatingAddress(true),
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return {
    countries,
    states,
    cities,
    isCreatingAddress,
    handleOnSubmitForm,
  };
};
