import { CSSProperties } from 'styled-components';

export const CONTACT_STYLES = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
  } as CSSProperties,
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  } as CSSProperties,
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties,
  formFieldsContainer: {
    maxWidth: '800px',
    width: '100%',
    height: "auto",
    padding: '40px',
    borderRadius: '20px',
    border: '2px solid rgb(0, 102, 128)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  nameContainer: {
    display: 'flex',
    gap: '30px',
    '@media screen and (max-width: 600px)': {
      flexDirection: 'column',
    } as CSSProperties,
  } as CSSProperties,
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flexGrow: '1',
    padding: '10px',
  } as CSSProperties,
  inputForm: {
    padding: '0 1.2rem',
    height: '40px',
    borderWidth: '1px',
    borderRadius: '5px',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    color: 'rgb(0, 0, 0)',
    borderStyle: 'solid',
  } as CSSProperties,
  textArea: {
    minWidth: '100%',
    width: '100%',
    maxWidth: '100%',
    minHeight: '150px',
  } as CSSProperties,
  sendButton: {
    padding: "6px 30px",
  } as CSSProperties,
};
