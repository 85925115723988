import {
  ExchangedVoucherData,
  ExchangedVoucherTypeOfDiscount,
  FormateadExchangedVouchers,
  IVoucherDictionary,
} from '../../common/types/voucher';
import { formatNumber, getValueFromObject } from '../functions';

export const formatExchangedVouchers = (
  vouchers: ExchangedVoucherData[],
  dictionary: IVoucherDictionary
): FormateadExchangedVouchers[] => {
  const formateadVouchers = vouchers?.map((voucher) => {
    const haveAPercentageDiscount: boolean = Boolean(
      voucher?.tipoDescuento === ExchangedVoucherTypeOfDiscount.PORCENTAJE
    );

    return {
      idCupon: voucher?.idCuponMovimiento, //* El detalle del cupón canjeado requiere el idCuponMovimiento
      imagenMiniatura: voucher?.imagenMiniatura || null,
      titulo: voucher?.cuponNombre,
      descuentoText: haveAPercentageDiscount
        ? dictionary.detail.discount.replace('{value}', formatNumber(voucher?.descuento))
        : dictionary.detail.fixedValue.replace('{value}', formatNumber(voucher?.descuento)),
      statusText: getValueFromObject(dictionary.myVouchers.idVoucherStatus, voucher?.idCuponEstado) || null,
    };
  });

  return formateadVouchers;
};
