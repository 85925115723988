import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/';
import InputForm from '../../../../components/Inputs/InputForm/InputForm';
import SelectForm from '../../../../components/Inputs/SelectForm/SelectForm';
import useCarrito from '../../../../common/hooks/useCarrito';
import { useNewAddressForm } from '../../hooks/useNewAddressForm';

type Props = {
  form: any;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  setFormErr: React.Dispatch<React.SetStateAction<any>>;
  err?: boolean;
};

const styleAcordion = {
  maxWidth: '600px',
  width: '100%',
  backgroundColor: 'rgb(219, 219, 219)',
};

export const NewAddressForm: React.FC<Props> = ({ form, setForm, err = false, setFormErr }) => {
  const { dictionary } = useCarrito();
  const { cities, countries, states, isCreatingAddress, handleOnSubmitForm } = useNewAddressForm({
    saveBtnText: dictionary?.formCarrito.row2.body.botones.guardar,
    noSaveBtnText: dictionary?.formCarrito.row2.body.botones.noGuardar,
    cancelBtnText: dictionary?.formCarrito.row2.body.botones.cancelar,
    guardarDireccion: dictionary?.formCarrito.row2.body.guardarDireccion,
    guardarDireccionBody: dictionary?.formCarrito.row2.body.guardarDireccionBody,
    successText: dictionary?.formCarrito.row2.body.addressSuccess,
    errorText: dictionary?.formCarrito.row2.body.errorText,
    form,
    setForm,
    setFormErr,
  });

  return (
    <Accordion sx={styleAcordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography aria-label="new-address-title">{dictionary?.formCarrito.row2.body.datosNuevaDireccion}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box textAlign="right">
          <Button
            aria-label="add-button"
            variant="contained"
            onClick={handleOnSubmitForm}
            disabled={isCreatingAddress}
          >
            {dictionary?.formCarrito.validaciones.agregar}
          </Button>
        </Box>
        <InputForm
          required
          arialLabel="input-street"
          name="calle"
          type="text"
          label={dictionary?.formCarrito.row2.body.calle}
          isDisabled={isCreatingAddress}
          err={err}
          form={form}
          setForm={setForm}
        />
        <InputForm
          required
          arialLabel="input-street-number"
          name="calleNumero"
          type="number"
          label={dictionary?.formCarrito.row2.body.calleNumero}
          isDisabled={isCreatingAddress}
          err={err}
          form={form}
          setForm={setForm}
        />
        <InputForm
          required
          arialLabel="input-zip-code"
          name="codigoPostal"
          type="number"
          label={dictionary?.formCarrito.row2.body.codigoPostal}
          isDisabled={isCreatingAddress}
          err={err}
          form={form}
          setForm={setForm}
        />
        <InputForm
          arialLabel="input-floor"
          name="piso"
          type="number"
          label={dictionary?.formCarrito.row2.body.piso}
          isDisabled={isCreatingAddress}
          err={err}
          form={form}
          setForm={setForm}
        />
        <InputForm
          arialLabel="input-apartment"
          name="departamento"
          type="text"
          label={dictionary?.formCarrito.row2.body.departamento}
          isDisabled={isCreatingAddress}
          form={form}
          setForm={setForm}
        />
        <SelectForm
          arialLabel="country-select"
          name="idPais"
          required
          readonly={isCreatingAddress}
          label={dictionary?.formCarrito.row2.body.pais}
          form={form}
          err={err}
          setForm={setForm}
          children={countries?.map(({ id, nombre }) => (
            <option
              aria-label={`${id}-country-option`}
              key={id}
              value={id}
            >
              {nombre}
            </option>
          ))}
        />
        <SelectForm
          arialLabel="state-select"
          name="idProvincia"
          required
          readonly={isCreatingAddress}
          label={dictionary?.formCarrito.row2.body.provincia}
          form={form}
          err={err}
          setForm={setForm}
          children={states?.map(({ id, nombre }) => (
            <option
              aria-label={`${id}-state-option`}
              key={id}
              value={id}
            >
              {nombre}
            </option>
          ))}
        />
        <SelectForm
          arialLabel="city-select"
          name="idLocalidad"
          required
          readonly={isCreatingAddress}
          label={dictionary?.formCarrito.row2.body.localidad}
          form={form}
          err={err}
          setForm={setForm}
          children={cities?.map(({ id, nombre }) => (
            <option
              aria-label={`${id}-city-option`}
              key={id}
              value={id}
            >
              {nombre}
            </option>
          ))}
        />
      </AccordionDetails>
    </Accordion>
  );
};
