import { NavigateFunction } from 'react-router-dom';
import { Grid } from '@mui/material';
import { FormateadExchangedVouchers, FormateadVoucher } from '../../../../common/types/voucher';
import { VoucherCard } from './VoucherCard';
import { VOUCHER_CARD_STYLES } from './styles';

interface VoucherCardContainerProps {
  formateadVouchers: FormateadVoucher[] | FormateadExchangedVouchers[];
  onClick?: NavigateFunction | ((idCupon: number) => void);
}

export const VoucherCardContainer: React.FC<VoucherCardContainerProps> = ({ formateadVouchers, onClick }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={VOUCHER_CARD_STYLES.voucherContainerMainContainer}
    >
      {formateadVouchers?.map(({ idCupon, descuentoText, imagenMiniatura, titulo, pointsText, statusText }) => (
        <VoucherCard
          key={idCupon}
          discountText={descuentoText}
          image={imagenMiniatura}
          title={titulo}
          pointsText={pointsText}
          statusText={statusText}
          onClick={() => onClick(idCupon)}
        />
      ))}
    </Grid>
  );
};
