import { useQuery } from '@tanstack/react-query';
import { getAddressesList, getPersonByIdUser, getPointsByPerson } from '../../../services/person';
import { useAppContext } from '..';
import { IdFormaEntrega } from '../../types';

interface IPerson {
  isFetchingUserPoints?: boolean;
  isFetchingUser?: boolean;
  addressesListIdFormaEntrega?: number;
}

export const usePerson = ({ isFetchingUserPoints, isFetchingUser, addressesListIdFormaEntrega }: IPerson) => {
  const { idUsuario, idPersona, idCliente } = useAppContext();

  const clientPointsQuery = useQuery({
    queryKey: ['person-client-points', { idUsuario }],
    queryFn: async () => await getPointsByPerson({ idPersona, idCliente }),
    enabled: Boolean(idUsuario && isFetchingUserPoints),
  });

  const personUserQuery = useQuery({
    queryKey: ['person-user', { idUsuario }],
    queryFn: async () => await getPersonByIdUser(idUsuario),
    enabled: Boolean(idUsuario && isFetchingUser),
  });

  const addressesListQuery = useQuery({
    queryKey: ['person-addresses-list', { idUsuario, idPersona, addressesListIdFormaEntrega }],
    queryFn: async () => await getAddressesList(idPersona),
    enabled: Boolean(idUsuario && addressesListIdFormaEntrega === IdFormaEntrega.DELIVERY_BY_ADDRESS),
  });

  return { idUsuario, idPersona, clientPointsQuery, personUserQuery, addressesListQuery };
};
