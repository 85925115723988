import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { resetContador } from "../../../../common/redux/features/contadorItemReducer";
import { resetItems } from "../../../../common/redux/features/carritoReducer";
import { PedidoDetalle } from "../../../../common/interfaces/CarritoInterface";
import "./ModalCanjeCarrito.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  setOpen: any;
  pedidoDetalle: PedidoDetalle[];
};

export const ModalCanjeCarrito: React.FC<Props> = ({
  open,
  setOpen,
  pedidoDetalle,
}) => {
  const handleClose = () => {
    dispatch(resetContador());
    dispatch(resetItems());
    setOpen(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(resetContador());
    dispatch(resetItems());
    navigate("/catalogo");
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 20000 }}
        // closeAfterTransition
        slots={{ backdrop: Backdrop }}>
        <Fade in={open}>
          <Box
            sx={style}
            textAlign={"center"}
            position={"relative"}
            width={{ xs: "100%", sm: "100%", md: 800 }}>
            <Box
              sx={{ position: "absolute", top: "10px", right: "20px" }}
              onClick={handleClose}>
              <CloseIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
            </Box>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Pedido
            </Typography>
            <Box
              marginTop={3}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              justifyContent={"center"}
              alignItems={"center"}>
              <div className="pedido-canje-modal">
                <p>Id Pedido</p>
                <p>Producto</p>
                <p className="">Estado</p>
              </div>
              {pedidoDetalle?.map((el: PedidoDetalle) => (
                <div className="pedido-canje-modal" key={el.idPedido}>
                  <p>{el.idPedido}</p>
                  <p>{el.nombreProducto || el.nombreCupon}</p>
                  <p className="">{el.estado}</p>
                </div>
              ))}
              <Box width="fit-content">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClick()}>
                  Ir al Catalogo
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
