import { CSSProperties } from 'styled-components';

export const SORTING_LIST_STYLES = {
  select: {
    '&.MuiOutlinedInput-root': {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      color: 'var(--color-light-gray)',
      border: 'none',
      '&.Mui-focused fieldset': {
        fontFamily: 'Poppins, sans-serif',
        border: 'none',
      },
    },
    fieldset: {
      border: 'none',
    },
  } as CSSProperties,
  selectOption: (queryParam: string, currentSorting: string, color: string, defaultColor: string) => {
    const isOptionSelected: boolean = Boolean(queryParam === currentSorting);
    return {
      color: isOptionSelected ? color : defaultColor,
      fontWeight: isOptionSelected ? 700 : 200,
      fontFamily: 'Poppins, sans-serif',
    } as CSSProperties;
  },
};
