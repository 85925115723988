import { apiUrlRecognitions } from '../../../common/types';
import { IRecognitions, IRecognitionsQueryParams } from '../../../common/types/recognitions';
import { fetchContent } from '../../../utils/fetchUtils';
import { setQueryParams } from '../../../utils/functions';
import { handleServiceError } from '../../GiftCardService';

export const getMadeRecognitions = async (recognition: IRecognitionsQueryParams): Promise<IRecognitions> => {
  const URL: string = setQueryParams(apiUrlRecognitions.myRecognitions, recognition);

  try {
    const response: IRecognitions = await fetchContent(URL);

    return response;
  } catch (error) {
    console.error('[getMadeRecognitions]: ' + error);
    throw handleServiceError(error);
  }
};
