import { CSSProperties } from 'react';

export const VOUCHER_DETAIL_MODAL_STYLES = {
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '35rem',
    maxWidth: '100%',
    boxSizing: 'border-box',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    backgroundColor: '#f8f9f4',
    borderRadius: '1rem',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '1rem',
  } as CSSProperties,
  closeIconContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
  } as CSSProperties,
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 0,
    marginTop: '0.5rem',
  } as CSSProperties,
  legendContainer: {
    width: '100%',
    position: 'relative',
    left: '8px',
  } as CSSProperties,
  legend: {
    display: 'flex',
    gap: '1rem',
  } as CSSProperties,
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
  } as CSSProperties,
  body: {
    display: 'flex',
  } as CSSProperties,
  descriptionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  } as CSSProperties,
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
    position: 'relative',
    left: '8px',
  } as CSSProperties,
  title: {
    fontWeight: 'bold',
  } as CSSProperties,
};
