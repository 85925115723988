import { useContext } from 'react';
import { LanguageContext } from '../../context';

export const useLanguageContext = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  return {
    language,
    setLanguage,
  };
};
