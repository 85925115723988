import { Link } from 'react-router-dom';
import { Box, Hidden, Typography } from '@mui/material';
import {
  RedeemOutlined as RedeemOutlinedIcon,
  CurrencyBitcoinOutlined as CurrencyBitcoinOutlinedIcon,
  SportsSoccerOutlined as SportsSoccerOutlinedIcon,
  EmojiEventsOutlined as EmojiEventsOutlinedIcon,
  ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon,
} from '@mui/icons-material/';
import MenuDropdown from '../../Menu/MenuDropDown/Index';
import { AuthUser } from '../../../context';
import { CRIPTO_SUB_MENU, RECONOCIMIENTO_SUB_MENU, RUTAS } from '../../../common/constants/rutas';
import { VOUCHER_SUB_MENU } from '../../../common/constants/routes/voucherRoutes';
import { CENTER_NAVBAR_STYLES } from './style';

interface CenterNavbarProps {
  user: AuthUser;
  isHasCryptoPermission: boolean;
  isHasRecognitionsPermission: boolean;
  isHasVoucherPermission: boolean;
  catalog: string;
  voucherTitle: string;
  crypto: string;
  cup: string;
  recognitions: string;
}

const CenterNav: React.FC<CenterNavbarProps> = ({
  user,
  isHasCryptoPermission,
  isHasRecognitionsPermission,
  isHasVoucherPermission,
  catalog,
  voucherTitle,
  crypto,
  cup,
  recognitions,
}) => {
  return (
    <Hidden mdDown>
      <Box
        fontSize={14}
        sx={CENTER_NAVBAR_STYLES.mainContainer}
      >
        <Link
          to={RUTAS.catalogo}
          style={CENTER_NAVBAR_STYLES.link}
        >
          <RedeemOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />
          <Typography variant="subtitle2">{catalog}</Typography>
        </Link>

        {user && isHasVoucherPermission && (
          <MenuDropdown
            buttonText={voucherTitle}
            buttonIcon={<ConfirmationNumberOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />}
            subMenu={VOUCHER_SUB_MENU}
          />
        )}

        {user && isHasCryptoPermission ? (
          <MenuDropdown
            buttonText={crypto}
            buttonIcon={<CurrencyBitcoinOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />}
            subMenu={CRIPTO_SUB_MENU}
          />
        ) : (
          <Link
            to={RUTAS.cripto}
            style={CENTER_NAVBAR_STYLES.link}
          >
            <CurrencyBitcoinOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />
            <Typography variant="subtitle2">{crypto}</Typography>
          </Link>
        )}

        <Link
          to={RUTAS.copa}
          target="_blank"
          rel="noopener noreferrer"
          style={CENTER_NAVBAR_STYLES.link}
        >
          <SportsSoccerOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />
          <Typography variant="subtitle2">{cup}</Typography>
        </Link>

        {user && isHasRecognitionsPermission && (
          <MenuDropdown
            buttonText={recognitions}
            buttonIcon={<EmojiEventsOutlinedIcon sx={CENTER_NAVBAR_STYLES.icon} />}
            subMenu={RECONOCIMIENTO_SUB_MENU}
          />
        )}
      </Box>
    </Hidden>
  );
};

export default CenterNav;
