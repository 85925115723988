export interface IDeliveryOptions {
  idFormaEntrega: number;
  id: number;
  descripcion: FormaEntregaDescripcion;
}

enum FormaEntregaDescripcion {
  DELIVERY_BY_ADDRESS = 'Entrega a domicilio',
  DELIVERY_BY_POINT = 'Lo retiro de un punto de entrega',
  DELIVERY_BY_EMAIL = 'Envío por mail',
}

export enum IdFormaEntrega {
  DELIVERY_BY_ADDRESS = 1,
  DELIVERY_BY_POINT = 2,
  DELIVERY_BY_EMAIL = 3,
}
