import avatar from '../assets/images/Avatar/mujer.png';
import avatar1 from '../assets/images/Avatar/mujer1.png';
import avatar2 from '../assets/images/Avatar/mujer2.png';
import avatar3 from '../assets/images/Avatar/hombre.png';
import avata4 from '../assets/images/Avatar/hombre1.png';
import avatar5 from '../assets/images/Avatar/hombre2.png';
//iconos de paises
import eeuu from '../assets/images/banderas/eeuu.png';
import espana from '../assets/images/banderas/espana.png';
import { convertHourToMilliseconds } from '../../utils';

export const AVATAR_ROUTES = [
  {
    id: 1,
    avatar: 'mujer.png',
    icono: avatar,
  },
  {
    id: 2,
    avatar: 'mujer1.png',
    icono: avatar1,
  },
  {
    id: 3,
    avatar: 'mujer2.png',
    icono: avatar2,
  },
  {
    id: 4,
    avatar: 'hombre.png',
    icono: avatar3,
  },
  {
    id: 5,
    avatar: 'hombre1.png',
    icono: avata4,
  },
  {
    id: 6,
    avatar: 'hombre2.png',
    icono: avatar5,
  },
];

export const formularioEstructura = {
  idContactanos: 0,
  idCliente: 0,
  idUsuario: 0,
  email: '',
  nombre: '',
  descripcion: '',
  idMotivoContacto: -1,
  fecha: '2023-06-21T18:46:22.432Z',
  asunto: '',
};

export const cliente: number = process.env.REACT_APP_CLIENTE ? parseInt(process.env.REACT_APP_CLIENTE) : 0;

export const headerOptions = {
  'Content-Type': 'application/json',
  valid: '98bd0aa2-95d3-4f5a-adcf-df2afaf1eed01de41feb-eab8-423e-998f-a6c960ffcb8b',
  'Content-Encoding': 'gzip, deflate, br',
  Accept: 'application/json, text/plain, */*',
};

export const FILTRO_PRECIO = [
  {
    minPrice: 0,
    maxPrice: 2000,
    label: 'Menos de 2000',
  },
  {
    minPrice: 2000,
    maxPrice: 5000,
    label: 'Entre 2000 y 5000',
  },
  {
    minPrice: 5000,
    maxPrice: 15000,
    label: 'Entre 5000 y 15000',
  },
  {
    minPrice: 15000,
    maxPrice: Number.POSITIVE_INFINITY,
    label: 'Mas de 15000',
  },
];

export const URL_IMAGEN_PRODUCTOS = 'https://webtop360testadmin.azurewebsites.net/images/productos/';

export const PAGINAS_POR_REGISTRO = 9;
export const bodyFilterInit = {
  pagina: 1,
  cantidadRegistrosPorPagina: PAGINAS_POR_REGISTRO,
  categorias: [],
  marcas: [],
  tiposProducto: [],
  paises: null,
  puntos: 0,
  puntosHasta: 100000,
  ordenamiento: 'Destacado desc',
};

export const FILTROS_OPTIONS_INIT = {
  ClienteId: cliente,
  categorias: [],
  marcas: [],
  paises: ['032', '076'],
  tiposProducto: [],
  ordenamiento: 'tituloProducto asc',
};

export const ORDENAMIENTO_OPCIONES = [
  {
    value: 'Destacado desc',
    text: 'Más utilizadas',
    dictionarySearch: 'masUtilizadas',
  },
  {
    value: 'FechaAlta desc',
    text: 'Más recientes',
    dictionarySearch: 'masRecientes',
  },
  {
    value: 'Precio asc',
    text: 'Menor precio',
    dictionarySearch: 'menorPrecio',
  },
  {
    value: 'Precio desc',
    text: 'Mayor precio',
    dictionarySearch: 'mayorPrecio',
  },
  {
    value: 'MarcaDescripcion asc',
    text: 'Marca creciente',
    dictionarySearch: 'marcaCreciente',
  },
  {
    value: 'MarcaDescripcion desc',
    text: 'Marca decreciente',
    dictionarySearch: 'marcaDecreciente',
  },
  {
    value: 'CategoriaDescripcion asc',
    text: 'Categoría creciente',
    dictionarySearch: 'categoriaCreciente',
  },
  {
    value: 'CategoriaDescripcion desc',
    text: 'Categoría decreciente',
    dictionarySearch: 'categoriaDecreciente',
  },
];

export const LANGUAGE_OPTIONS = [
  {
    text: 'EN',
    icon: eeuu,
  },
  {
    text: 'ES',
    icon: espana,
  },
];

export const CRYPTO_CURRENCY: string[] = ['USD', 'ARS'];
export const DEFAULT_CURRENCY_HISTORICAL_PRICE: number = 7;

// constants.ts

export const TipoMovimiento_CanjePuntos = 2;
export const TipoMovimiento_CanjePuntosPorCrypto = 11;
export const TipoMovimiento_CompraPuntosConCrypto = 12;
export const FormaEntrega_Domicilio = 1;
export const FormaEntrega_RetiraPuntoEntrega = 2;

export const AvatarDefault = 'avatarDefault.png';

// Estados
export const Estado_Pendiente = 2;

// Proveedores SSO
export const SSO_Microsoft = 'Microsoft';
export const SSO_Facebook = 'Facebook';
export const SSO_Google = 'Google';

// Estados del cupon
export const CuponEstadoAsignado = 1;
export const CuponEstadoCanjeado = 2;
export const CuponEstadoSuspendido = 3;

export const PERMISOS_CODIGOS = {
  generarReconocimiento: 'GENRECON',
  webConsumidor: 'WEBCONSUM',
  aprobacionReconocimientos: 'APROBRECON',
  veMisReconocimientos: 'VERMYRECON',
  verMiEquipo: 'VEREQUIPO',
  criptomonedas: 'WCRIPTO',
  misMovimientos: 'WMISMOV',
  misDatos: 'WMISDAT',
  educacionFinanciera: 'WEDUCA',
  contactanos: 'WCONTACT',
  usaRangoPreciosEnGiftCard: 'USARANGOPR',
  dashboardKiwinEnHome: 'WDASHKIWIN',
  verLinkTerminosCondicionesProducto: 'VERLNKTYC',
  catalogoGiftCard: 'WGIFTCARD',
  reconocimientosEnDashboardWeb: 'WDSRECONOC',
  cupones: 'CUPONES',
};

export const MOST_CONSUMED_PRODUCTS = {
  limit: 5,
  sort: 'porcentaje desc',
};

export const LATEST_RECONOCIMIENTOS = {
  page: 1,
  limit: 5,
  sort: 'fecha desc',
};

export const DEFAULT_STALE_TIME: number = convertHourToMilliseconds(1);
export const RECENT_TRANSACTIONS_DEFAULT_RECORDS_PER_PAGE: number = 5;
export const CANTIDAD_REGISTROS_POR_PAGINA: number = 10;
