import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import notFound from '../../../../common/assets/images/giftcards/icon-not-found.png';
import { VOUCHER_CARD_STYLES } from './styles';

interface VoucherCardProps {
  image: string;
  discountText: string;
  title: string;
  pointsText: string;
  onClick: any;
  statusText?: string;
}

export const VoucherCard: React.FC<VoucherCardProps> = ({
  discountText,
  image,
  title,
  pointsText,
  statusText,
  onClick,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
    >
      <Box
        sx={{ ...VOUCHER_CARD_STYLES.voucherCard, ...VOUCHER_CARD_STYLES.voucherCardHover() }}
        onClick={onClick}
      >
        <Box sx={VOUCHER_CARD_STYLES.imageContainer}>
          <img
            src={image ?? notFound}
            alt={title}
            style={VOUCHER_CARD_STYLES.image}
          />
        </Box>

        <Box sx={VOUCHER_CARD_STYLES.titleContainer}>
          <Typography
            variant="h6"
            color="primary"
            sx={VOUCHER_CARD_STYLES.title}
          >
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            color="primary"
            sx={VOUCHER_CARD_STYLES.discountText}
          >
            {discountText}
          </Typography>
        </Box>

        <Box sx={VOUCHER_CARD_STYLES.pointsContainer}>
          <Typography
            variant="h6"
            color="primary"
            sx={VOUCHER_CARD_STYLES.pointsText}
          >
            {statusText || pointsText}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
