import { apiUrlState, State } from '../../common/types';
import { fetchContent } from '../../utils/fetchUtils';
import { handleServiceError } from '../GiftCardService';

export const getStates = async (): Promise<State[]> => {
  const URL: string = apiUrlState.state;

  try {
    const states: State[] = await fetchContent(URL);

    return states;
  } catch (error: any) {
    console.error('[getStates]: ' + error);
    throw handleServiceError(error);
  }
};
