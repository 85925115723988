import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommonVoucher } from '../../common/hooks';
import { FormateadVoucher } from '../../common/types/voucher';
import { VOUCHERS_PER_PAGE } from './constants';
import { formatVouchers } from '../../utils';
import { useVouchers } from './useVouchers';
import { Route } from '../../common/types';

export const useVouchersCatalog = () => {
  const {
    dictionary,
    currentSorting,
    page,
    isSelectedOption,
    handleSelectOption,
    setIsSelectedOption,
    handleChangePage,
  } = useVouchers();
  const navigate = useNavigate();

  const {
    vouchersQuery: { data: vouchers, isLoading, isError },
  } = useCommonVoucher({
    vouchersByClient: {
      ordenamiento: currentSorting,
      pagina: page,
      cantidadRegistrosPorPagina: VOUCHERS_PER_PAGE,
    },
  });

  const formateadVouchers: FormateadVoucher[] = useMemo(
    () => (vouchers?.data ? formatVouchers(vouchers?.data, dictionary) : []),
    [vouchers?.data, dictionary]
  );

  const handleNavigate = (idCupon: number): void => {
    navigate(Route.VOUCHER.replace(':id', `${idCupon}`));
  };

  const isVouchers: boolean = Boolean(formateadVouchers.length > 0);

  return {
    dictionary,
    currentSorting,
    vouchers,
    page,
    formateadVouchers,
    isLoading,
    isSelectedOption,
    isError,
    isVouchers,
    handleSelectOption,
    setIsSelectedOption,
    handleNavigate,
    handleChangePage,
  };
};
